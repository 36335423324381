// Copyright ©️ 2025 eVolve MEP, LLC
import { useState } from 'react';

import { Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { DateInputWrapped } from 'components/Mantine/DateInputWrapped';
import type { DateOnly } from 'helpers/dateOnly';
import { isNil } from 'helpers/isNotNil';
import { useWrappedPatch } from 'hooks-api/useWrappedApiCall';

import type { WorkRequest } from './types';

type Props = {
  opened: boolean;
  onClose: () => void;
  onUpdate: (updatedWorkRequest: WorkRequest) => void;
  onComplete: () => void;
  workRequest: WorkRequest;
};

export const NeedsNeedByModal = ({ opened, onClose, workRequest, onUpdate, onComplete }: Props) => {
  const [needBy, setNeedBy] = useState<DateOnly | null>(null);
  const { apiCall: updateNeedByDate, loading } = useWrappedPatch<WorkRequest, { needBy: DateOnly }>(
    `shop/workRequest/${workRequest.workRequestId}`,
  );
  return (
    <ConfirmationModal
      title="Set need by date"
      disabled={isNil(needBy)}
      opened={opened}
      onClose={onClose}
      loading={loading}
      onConfirm={() => {
        if (isNil(needBy)) return;
        void updateNeedByDate({ needBy }).then(onUpdate).then(onComplete);
      }}
    >
      <Text mb="xs" fz="sm">
        A work request cannot be submitted without a need by date.
      </Text>
      <DateInputWrapped
        popoverProps={{
          withinPortal: true,
        }}
        style={{ width: '100%' }}
        label="Need By Date"
        minDate={new Date()}
        leftSection={<EvolveIcon icon="CalendarBoard" />}
        value={needBy}
        disabled={loading}
        dateOnly
        onChange={(date) => setNeedBy((d) => date ?? d)}
      />
    </ConfirmationModal>
  );
};
