// Copyright ©️ 2025 eVolve MEP, LLC

import { useCallback, useEffect, useRef, useState } from 'react';

import { Button, type ButtonProps, Flex, Loader, Text } from '@mantine/core';
import { differenceInSeconds } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import type { TaskStatusTypeName } from 'constants/badgeMappingStatus';
import type { StrongOmit } from 'helpers/helper-types';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import type { Task } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { convertSecondsToTimestamp, convertTimestampToSeconds } from './utils';
import type { TaskStatusType, TaskStatusTypeId } from '../../WorkCellQueue/WorkCellQueuePage/types';

type Props = {
  task: Task;
  onTaskUpdated: (task: Task) => void;
  taskStatuses: TaskStatusType[];
  disabled: boolean;
};
type Action = Extract<TaskStatusTypeName, 'Started' | 'Paused' | 'Completed'>;

const ControlButton = ({
  action,
  loading,
  actionTask,
  children,
  color = 'dark',
  ...props
}: StrongOmit<ButtonProps, 'loading'> & {
  action: Action;
  actionTask: (action: Action) => void;
  loading: Action | undefined;
}) => (
  <Button color={color} onClick={() => actionTask(action)} disabled={props.disabled || action === loading} {...props}>
    {action === loading ? <Loader size="sm" color={color} /> : children}
  </Button>
);

export const TaskControls = ({ task, taskStatuses, onTaskUpdated, disabled: parentDisabled }: Props) => {
  const [loading, setLoading] = useState<Action>();
  const { apiCall: updateTask } = useWrappedPatch<Task, { taskStatusTypeId: TaskStatusTypeId }>(
    `shop/task/${task.taskId}`,
  );

  const runningRef = useRef(false);
  const [totalTime, setTotalTime] = useState(task.time.totalTime);
  const [startMeasure, setStartMeasure] = useState(new Date());
  const [ellapsedTime, setEllapsedTime] = useState<string>(totalTime);

  useEffect(() => {
    if (task.taskStatusTypeName === 'Started') {
      const interval = setInterval(() => {
        if (runningRef.current) {
          const secondsEllapsed = differenceInSeconds(new Date(), startMeasure);
          const newTime = convertSecondsToTimestamp(convertTimestampToSeconds(totalTime) + secondsEllapsed);
          setEllapsedTime(newTime);
        }
      }, 500);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [startMeasure, task.taskStatusTypeName, totalTime]);

  useEffect(() => {
    setTotalTime(task.time.totalTime);
    runningRef.current = task.taskStatusTypeName === 'Started';
    if (runningRef.current) {
      setStartMeasure(new Date());
    }
  }, [task.taskStatusTypeName, task.time.totalTime]);

  const actionTask = useCallback(
    (action: Action) => {
      const taskStatusTypeId = taskStatuses.find((s) => s.taskStatusTypeName === action)?.taskStatusTypeId;
      if (isNil(taskStatusTypeId)) return;
      setLoading(action);
      void updateTask({ taskStatusTypeId })
        .then(onTaskUpdated)
        .finally(() => setLoading(undefined));
    },
    [onTaskUpdated, taskStatuses, updateTask],
  );

  const disabled = parentDisabled || isNotNil(loading) || !task.isEnabled;
  return (
    <Flex justify="space-between" align="center" gap="sm">
      <Flex align="center" gap="sm">
        <ControlButton
          action="Started"
          loading={loading}
          actionTask={actionTask}
          disabled={disabled || task.taskStatusTypeName === 'Started'}
          px="xs"
        >
          <EvolveIcon icon="Play" />
        </ControlButton>
        <ControlButton
          action="Paused"
          variant="outline"
          color="red"
          loading={loading}
          actionTask={actionTask}
          disabled={task.taskStatusTypeName !== 'Started'}
          px="xs"
        >
          <EvolveIcon icon="Pause" />
        </ControlButton>
        <Text c={task.taskStatusTypeName === 'Not Started' ? 'dimmed' : undefined}>{ellapsedTime}</Text>
      </Flex>
      <ControlButton
        action="Completed"
        loading={loading}
        actionTask={actionTask}
        disabled={disabled || task.taskStatusTypeName === 'Not Started'}
      >
        Complete
      </ControlButton>
    </Flex>
  );
};
