// Copyright ©️ 2025 eVolve MEP, LLC

import { Stack } from '@mantine/core';
import type { CustomFilterProps } from 'ag-grid-react';

import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import type { StrongOmit } from 'helpers/helper-types';
import { isNotNil } from 'helpers/isNotNil';

import type { HandledFilterModel } from './convertAgGridRequest';

type Props<T extends string> = {
  label: string;
  placeholder?: string;
  data: {
    value: T;
    label: string;
  }[];
  defaultValue?: T | null;
  filterKey?: string;
};

type FilterProps<T extends string> = StrongOmit<CustomFilterProps<any, T>, 'model' | 'onModelChange'> & {
  model: (HandledFilterModel<T> & { filterType: 'custom' }) | undefined;
  onModelChange: (newModel: FilterProps<T>['model'] | null) => void;
  buttons?: ['reset'];
};

export const CustomSelectComponent = <T extends string>({
  label,
  defaultValue,
  data,
  filterKey,
  placeholder,
}: Props<T>) =>
  function ({ model, onModelChange, buttons }: FilterProps<T>) {
    return (
      <Stack align="flex-end" p="sm">
        <WrappedSelect<T>
          value={model?.value ?? defaultValue}
          defaultValue={defaultValue}
          label={label}
          placeholder={placeholder}
          data={data}
          classNames={{
            // Necessary for AG Grid to not close the filter
            // when we click on the popover
            dropdown: 'ag-custom-component-popup',
          }}
          onChange={(value) => {
            if (isNotNil(value)) {
              onModelChange({
                filterType: 'custom',
                filterKey,
                value,
              });
            }
          }}
        />
        {buttons?.includes('reset') && (
          <button
            className="ag-button ag-standard-button ag-filter-apply-panel-button"
            onClick={() => onModelChange(null)}
          >
            Reset
          </button>
        )}
      </Stack>
    );
  };
