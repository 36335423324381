// Copyright ©️ 2025 eVolve MEP, LLC

import { FileButton, type FileButtonProps } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import type { StrongOmit } from 'helpers/helper-types';

type Props = StrongOmit<FileButtonProps, 'onChange' | 'multiple'> & {
  maxSize?: number;
  onChange: (img: File) => void;
};

const accept = ['image/png', 'image/jpg', 'image/jpeg'];

export const ImageUpload = ({ maxSize = 2 * 1000 * 1024, onChange, ...props }: Props) => (
  <FileButton
    accept={accept.join(',')}
    onChange={(img) => {
      if (!img) return;
      if (!accept.some((a) => a.toLocaleLowerCase() === img.type.toLocaleLowerCase())) {
        notifications.show({
          title: 'Unsupported file type',
          message: img.name,
          color: 'red',
        });
        return;
      }
      if (img.size > maxSize) {
        notifications.show({
          title: 'File too large',
          message: 'Max file size is 2MB.',
          color: 'red',
        });
        return;
      }
      onChange(img);
    }}
    {...props}
  />
);
