// Copyright ©️ 2025 eVolve MEP, LLC

import type { MessagePriority, PriorityId } from 'app/MessageCenter/types';
import type { SelectedView } from 'components/Mantine/ChangeTableView/useChangeTableView';
import type { ISOTimestamp } from 'helpers/dateOnly';
import type { StrongOmit } from 'helpers/helper-types';
import type { DepartmentTypeId, ShopConfiguration } from 'hooks/projectsAndFacilities/types';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import type { UnitOfMeasureType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';
import type {
  WorkRequest,
  WorkRequestStatusType,
} from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import type { DocumentType } from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';
import type { TaskStatusType, WorkCellId } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';
import type { InsightsReq, NonParamFilters } from 'modules/Shop/Insights/types';
import type { PartCatalog } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';
import type { WorkOrderStatusType } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';
import type { EModuleType } from 'types/module';
import type { CompanyId, ProductId, UserId } from 'types/types-api';

import type { WorkRequestStatusName } from './badgeMappingStatus';

const singlePaneLayout = [{ x: 0, y: 0, w: 1, h: 1, i: '0' }];

const doublePaneLayout = [
  { i: '0', y: 0, w: 1, h: 1, minW: 1, x: 0 },
  { i: '1', y: 0, w: 1, h: 1, minW: 1, x: 1 },
];

const triplePaneLayout = [
  { i: '0', x: 0, y: 0, w: 1, h: 1, minW: 1 },
  { i: '1', x: 1, y: 0, w: 1, h: 2, minW: 1 },
  { i: '2', x: 0, y: 1, w: 1, h: 1, minW: 1 },
];

const quadruplePaneLayout = [
  { i: '0', x: 0, y: 0, w: 1, h: 1, minW: 1 },
  { i: '1', x: 1, y: 0, w: 1, h: 1, minW: 1 },
  { i: '2', x: 0, y: 1, w: 1, h: 1, minW: 1 },
  { i: '3', x: 1, y: 1, w: 1, h: 1, minW: 1 },
];

const getPaneLayouts = (numberOfPanes: number, sizes: number[] = []) => {
  const getPanesByNumber = () => {
    if (numberOfPanes === 1) return singlePaneLayout;
    if (numberOfPanes === 2) return doublePaneLayout;
    if (numberOfPanes === 3) return triplePaneLayout;
    if (numberOfPanes === 4) return quadruplePaneLayout;
    return quadruplePaneLayout;
  };

  const simpleLayouts = getPanesByNumber();
  const mappedLayout = getPanesByNumber().map((pane, index) => {
    if (sizes.length > 2) return pane;
    let correspondingIndex = index;
    if (index === 2) correspondingIndex = 0;
    if (index === 3) correspondingIndex = 1;

    return { ...pane, w: sizes[correspondingIndex] || pane.w };
  });

  return {
    lg: mappedLayout,
    md: mappedLayout,
    sm: simpleLayouts,
    xs: simpleLayouts,
    xxs: simpleLayouts,
  };
};

const HEADER_HEIGHT = 64;
const PANES_PADDING = 10;

export const buildDefaultLayoutSettings = (numberOfPanes: number, height: number, sizes: number[] = []) => {
  const rows = numberOfPanes <= 2 ? 1 : 2;

  let numberOfCols = numberOfPanes === 1 ? 1 : 2;

  if (sizes.length && sizes.length <= 2) {
    const sum = sizes.reduce((partialSum, a) => partialSum + a, 0);
    numberOfCols = sum + (2 - sizes.length);
  }

  return {
    isDraggable: true,
    isResizable: true,
    rows,
    maxRows: rows,
    preventCollision: false,
    allowOverlap: false,
    draggableHandle: '.draggable-area',
    layouts: getPaneLayouts(numberOfPanes, sizes),
    cols: { lg: numberOfCols, md: numberOfCols, sm: 1, xs: 1, xxs: 1 },
    rowHeight: (height - HEADER_HEIGHT - (rows + 1) * PANES_PADDING) / rows,
  };
};

export const EVOLVE_COMPANY_ID = 'c453c75e-d878-41b7-9f32-a87b302d4c80' as CompanyId;

export type DeviceSize = 'DESKTOP' | 'TABLET' | 'MOBILE';

export const MESSAGE_PRIORITIES = {
  HIGH: 'e3ee36f5-fbb6-4886-a63e-43ae04c14877' as PriorityId,
  NORMAL: '582634fe-763b-4364-acc1-fbbf5b7e387b' as PriorityId,
} as const satisfies Record<MessagePriority, PriorityId>;

export const DEPARTMENT_TYPE_IDS = {
  DESIGN_TYPE_DEPARTMENT_ID: 'bb0c5132-a15a-49a7-ba0c-9493b80942c9' as DepartmentTypeId,
  PROCUREMENT_TYPE_DEPARTMENT_ID: '776d41c5-965a-4620-b40d-c3c847ccf7a0' as DepartmentTypeId,
  SHOP_TYPE_DEPARTMENT_ID: '6edd6cfb-08d0-4a19-8ed2-3ee64320ebf0' as DepartmentTypeId,
} as const satisfies Record<string, DepartmentTypeId>;

export const PRODUCT_IDS = {
  MECHANICAL_LICENSE: 'fdb6375d-09ee-11ed-82eb-1296f7ac1dd9' as ProductId,
  ELECTRICAL_LICENSE: 'fdb63ec7-09ee-11ed-82eb-1296f7ac1dd9' as ProductId,
  SHOP_LICENSE: 'fec5765a-8c00-401f-82cb-e1d1b37dd3d6' as ProductId,
  DESIGN_LICENSE: 'fdb63b2a-09ee-11ed-82eb-1296f7ac1dd9' as ProductId,
  FIELD_LICENSE: '7ec6d47b-0121-4843-9bf7-c7e25a5a3ccf' as ProductId,
  MATERIALS_LICENSE: '3cd1c0cc-14ff-454f-bd0d-191c5c00de04' as ProductId,
  SYNC_LICENSE: '1ee2791a-c2c3-42c5-b0f4-71bed02c4268' as ProductId,
} as const satisfies Record<string, ProductId>;

export const FILE_FORMATS = {
  PDF: 'PDF',
  ANOTHER_FILE: 'ANOTHER_FILE',
} as const;

export const INCLUDE_DELETED_OPTIONS = {
  YES: 'yes',
  NO: 'no',
  ONLY_DELETED: 'only deleted',
} as const;

export type LOCAL_STORAGE_TYPE = {
  SELECTED_CATALOG: PartCatalog | null;
  EVOLVE_ACCESS_TOKEN: string;
  EVOLVE_REFRESH_TOKEN: string;
  STAY_SIGNED_IN: 'true' | null;
  SELECTED_ITEM: SelectedItem | null;
  CURRENT_MODULE: EModuleType;
  CURRENT_PAGE: string;
  SELECTED_WORK_CELL: WorkCellId | null;
  WORK_REQUEST_STATUSES: WorkRequestStatusType[] | undefined;
  WORK_ORDER_STATUSES: WorkOrderStatusType[] | undefined;
  TASK_STATUSES: TaskStatusType[] | undefined;
  DOCUMENT_TYPES: DocumentType[] | undefined;
  UNIT_OF_MEASURE_TYPE: UnitOfMeasureType[] | undefined;
  INSIGHTS_FILTERS: StrongOmit<InsightsReq & NonParamFilters, 'currentDate'>;
  INSIGHTS_DETAILED: boolean;
  ACTIVE_SHOP_CONFIGURATION: ShopConfiguration | undefined;

  // Computed keys
  [key: `TABLE_VIEW_${string}`]: SelectedView;
  [key: `${UserId}-${WorkRequest['workRequestIdentifier']}-${WorkRequestStatusName}-accepted`]: boolean | ISOTimestamp;
  [key: `GRID_${string}_v${number}`]: string; // raw JSON
};

export type LOCAL_STORAGE_KEY = keyof LOCAL_STORAGE_TYPE;

export const UPLOAD_FILE_EXTENSIONS =
  /\.(jpg|jpeg|png|gif|bmp|tiff|svg|txt|xls|xlsx|ppt|pptx|doc|docx|csv|pdf|dxf|al|psd|rvt|maj|caj|esj|dwg|dxf|cad|ipt|iam|skp)$/i;
