// Copyright ©️ 2025 eVolve MEP, LLC
import './attachments.css';
import { type ReactNode, useState } from 'react';

import { Flex, Loader, Text } from '@mantine/core';
import type { ColDef } from 'ag-grid-enterprise';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { TextWithRef } from 'components/Mantine/TextWithRef';
import { withHttp } from 'helpers/common';
import { formatDate } from 'helpers/dateOnly';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useUsersInfo } from 'hooks/useUsersInfo';

import type { AttachmentSectionName } from './AttachmentsList';
import { DeleteAttachment } from './DeleteAttachment';
import type { Document, DocumentSkeleton, RenderableDocument } from './types';
import { useDocumentsCache } from './useDocumentsCache';

const LinkFollower = ({
  attachment,
  children,
  rowIndex,
  section,
  onDocumentSelected,
}: {
  attachment: DocumentSkeleton;
  children: ReactNode;
  rowIndex: number;
  section: AttachmentSectionName;
  onDocumentSelected?: (document: Document, rowIndex: number, section: AttachmentSectionName) => void;
}) => {
  const { user } = useUser();
  const { getPresignedUrl, attachmentToDocumentMap } = useDocumentsCache();
  const { externalUrl } = attachment;
  const [presigning, setPresigning] = useState(false);
  return (
    <Flex
      onClick={() => {
        if (typeof externalUrl === 'string') {
          window.open(withHttp(externalUrl), '_blank');
        } else {
          const document = attachmentToDocumentMap[attachment.documentId];
          if (presigning || isNil(document)) return;
          if (onDocumentSelected) {
            onDocumentSelected(document, rowIndex, section);
            return;
          }
          setPresigning(true);
          void getPresignedUrl({
            objectKey: document.storagePath,
            expirationHours: 24,
            requestedBy: user.userId,
            verb: 'GET',
          })
            .then(({ preSignedURL }) => window.open(preSignedURL, '_blank'))
            .finally(() => setPresigning(false));
        }
      }}
      align="center"
      gap="sm"
      className="attachment-link"
      style={{ height: '100%', width: '100%' }}
      fz="sm"
    >
      {children}
      {presigning ? (
        <Loader size="xs" />
      ) : (
        <EvolveIcon
          className="show-on-row-hover"
          size="sm"
          icon={attachment.externalUrl || isNotNil(onDocumentSelected) ? null : 'Download'}
        />
      )}
    </Flex>
  );
};

const FileNameRenderer = ({ attachment }: { attachment: DocumentSkeleton }) => {
  const { attachmentToDocumentMap, loadingDocuments } = useDocumentsCache();
  const { externalUrl, documentId } = attachment;
  if (externalUrl !== undefined) {
    return <Text title={externalUrl}>{externalUrl}</Text>;
  }
  const document = documentId in attachmentToDocumentMap ? attachmentToDocumentMap[documentId] : undefined;
  if (isNotNil(document)) {
    return <Text title={document.documentNameFormatted}>{document.documentNameFormatted}</Text>;
  }
  if (loadingDocuments) {
    return <Loader size="sm" type="dots" />;
  }
  return '--';
};

const DateUploadedRenderer = ({
  attachment: { documentId, createdBy, createdOn },
}: {
  attachment: DocumentSkeleton;
}) => {
  const { attachmentToDocumentMap } = useDocumentsCache();
  const { cachedUserData } = useUsersInfo();
  const document = isNotNil(documentId) ? attachmentToDocumentMap[documentId] : null;
  const uploadedOn = document?.updatedOn ?? document?.createdOn ?? createdOn;
  const uploadedBy = document?.updatedBy ?? document?.createdBy ?? createdBy;
  const tooltip = `Uploaded${
    uploadedBy in cachedUserData ? ` by ${cachedUserData.get(uploadedBy)?.completeName}` : ''
  } at ${formatDate(uploadedOn, 'h:mmaaa')}`;
  return (
    <AgGridStyleTooltip label={tooltip} withArrow withinPortal openDelay={400}>
      <TextWithRef>{formatDate(uploadedOn, 'MM/dd/yyyy')}</TextWithRef>
    </AgGridStyleTooltip>
  );
};

export const getDocumentColumnDef = <T extends RenderableDocument>(
  locked: boolean,
  onUpdate: () => void,
  onDocumentSelected?: (document: Document, rowIndex: number, section: AttachmentSectionName) => void,
): ColDef<T>[] => [
  {
    width: 40,
    cellRenderer: CellRenderer<T>(
      ({ data }) => <EvolveIcon size="sm" icon={isNotNil(data.externalUrl) ? 'ExternalURL' : 'Attachment'} />,
      { flexProps: { justify: 'center' } },
    ),
  },
  {
    flex: 1,
    cellRenderer: CellRenderer<T>(({ data, node }) => (
      <LinkFollower
        attachment={data}
        onDocumentSelected={onDocumentSelected}
        rowIndex={node.rowIndex ?? 0}
        section={'taskId' in data ? 'Task' : 'workOrderId' in data ? 'Work Order' : 'Work Request'}
      >
        <FileNameRenderer attachment={data} />
      </LinkFollower>
    )),
  },
  {
    width: 100,
    cellRenderer: CellRenderer<T>(({ data }) => <DateUploadedRenderer attachment={data} />),
    cellStyle: {
      paddingLeft: 10,
    },
  },
  {
    lockPosition: 'right',
    width: 40,
    cellRenderer: CellRenderer<T>(({ data, api }) => (
      <DeleteAttachment
        document={data}
        refresh={() => {
          onUpdate();
          api.refreshServerSide({ purge: true });
        }}
      />
    )),
    hide: locked,
  },
];
