// Copyright ©️ 2025 eVolve MEP, LLC

import type { EvolveIconName } from 'assets/icons/EvolveIcon';
import type { EModuleType } from 'types/module';

export type NavOption = {
  displayText: string;
  icon: EvolveIconName;
  /**
   * Must be a Company admin to see this Nav Option.
   * Supersedes `shopAdminOnly` and `projectAdminOnly` if true.
   */
  companyAdminOnly?: boolean;
  /**
   * Must be a Shop admin to see this Nav Option.
   * If both `shopAdminOnly` and `projectAdminOnly` are true,
   * this Nav Option will be visible if the user is either a Shop or Project admin.
   */
  shopAdminOnly?: boolean;
  /**
   * Must be a Project admin to see this Nav Option.
   * If both `shopAdminOnly` and `projectAdminOnly` are true,
   * this Nav Option will be visible if the user is either a Shop or Project admin.
   */
  projectAdminOnly?: boolean;
} & (
  | {
      isMenu?: false;
      route: `/${string}`;
    }
  | {
      isMenu: true;
      options: [NavOption, ...NavOption[]];
    }
);

const dashboardMenu = {
  displayText: 'Dashboard',
  icon: 'Home',
  isMenu: true,
  shopAdminOnly: true,
  projectAdminOnly: true,
  options: [
    {
      displayText: 'Insights',
      icon: 'Insights',
      route: '/insights',
    },
  ],
} as const satisfies NavOption;

const companyAdminMenu = {
  displayText: 'Company',
  icon: 'Company',
  isMenu: true,
  options: [
    {
      displayText: 'Facilities',
      icon: 'Shops',
      route: '/facilities',
    },
    {
      displayText: 'Company Members',
      icon: 'ShopMembers',
      route: '/members/company-members',
    },
  ],
} as const satisfies NavOption;

const projectsMenu = {
  displayText: 'Projects',
  icon: 'Projects',
  isMenu: true,
  options: [
    {
      displayText: 'Projects',
      icon: 'Projects',
      route: '/projects',
    },
    {
      displayText: 'Project Members',
      icon: 'ShopMembers',
      route: '/members/project-members',
    },
    {
      displayText: 'Project Properties',
      icon: 'Settings',
      route: '/properties/project-properties',
    },
  ],
} as const satisfies NavOption;

const companyShopMenu = {
  displayText: 'Company',
  icon: 'Company',
  isMenu: true,
  options: [
    {
      displayText: 'Facilities',
      icon: 'Shops',
      route: '/facilities',
    },
    {
      displayText: 'Shop Members',
      icon: 'ShopMembers',
      route: '/shop-members',
    },
  ],
} as const satisfies NavOption;

const licensesItem = {
  displayText: 'Licenses',
  icon: 'Licenses',
  route: '/licenses',
} as const satisfies NavOption;

const workPlanningMenu = {
  displayText: 'Work Planning',
  icon: 'WorkPlanning',
  isMenu: true,
  options: [
    {
      displayText: 'Work Requests',
      icon: 'WorkRequest',
      route: '/work-requests',
    },
    {
      displayText: 'Work Orders',
      icon: 'WorkOrder',
      route: '/work-orders',
    },
    {
      displayText: 'Work Cells',
      icon: 'WorkCell',
      route: '/work-cells',
    },
  ],
} as const satisfies NavOption;

const fabricationMenu = {
  displayText: 'Fabrication',
  icon: 'Fabrication',
  isMenu: true,
  options: [
    {
      displayText: 'Work Cell Queue',
      icon: 'WorkCellQueue',
      route: '/work-cell-queue',
    },
  ],
} as const satisfies NavOption;

const catalogSetupItem = {
  displayText: 'Catalog Setup',
  icon: 'Catalog',
  route: '/catalog-setup',
} as const satisfies NavOption;

const evolveMembersItem = {
  displayText: 'Evolve Members',
  icon: 'EvolveMembers',
  route: '/evolve-members',
} as const satisfies NavOption;

const customersItem = {
  displayText: 'Customers',
  icon: 'Customers',
  route: '/evolve-customers',
} as const satisfies NavOption;

const companyFieldMenu = {
  displayText: 'Company',
  icon: 'Company',
  isMenu: true,
  options: [
    {
      displayText: 'Facilities',
      icon: 'Shops',
      route: '/facilities',
    },
  ],
} as const satisfies NavOption;

const workPlanningFieldMenu = {
  displayText: 'Work Planning',
  icon: 'WorkPlanning',
  isMenu: true,
  options: [
    {
      displayText: 'Work Requests',
      icon: 'WorkRequest',
      route: '/work-requests',
    },
  ],
} as const satisfies NavOption;

const companySyncMenu = {
  displayText: 'Company',
  icon: 'Company',
  isMenu: true,
  options: [
    {
      displayText: 'Facilities',
      icon: 'Shops',
      route: '/facilities',
    },
    {
      displayText: '360 Sync Members',
      icon: 'ShopMembers',
      route: '/360-sync-members',
    },
  ],
} as const satisfies NavOption;

const syncMenu = {
  displayText: '360 Sync',
  icon: 'Sync',
  isMenu: true,
  options: [
    {
      displayText: 'Workflows',
      icon: 'Workflows',
      route: '/workflows',
    },
    {
      displayText: 'Connectors',
      icon: 'Connections',
      route: '/connectors',
    },
    {
      displayText: 'Logs',
      icon: 'Logs',
      route: '/logs',
    },
    {
      displayText: 'Dashboard',
      icon: 'Dashboard',
      route: '/dashboard',
    },
  ],
} as const satisfies NavOption;

const configurationsMenu = {
  displayText: 'Configurations',
  icon: 'Configurations',
  isMenu: true,
  options: [
    {
      displayText: 'Work Cell Setup',
      icon: 'WorkCellsAndMachines',
      route: '/shop-setup/work-cell-setup',
    },
    {
      displayText: 'Catalog',
      icon: 'Catalog',
      route: '/catalog-setup',
    },
  ],
} as const satisfies NavOption;

/** Parent mapping of all modules to their navbar */
export const moduleMapping = {
  ADMIN: [companyAdminMenu, projectsMenu, 'divider', licensesItem],
  SHOP: [
    dashboardMenu,
    companyShopMenu,
    projectsMenu,
    'divider',
    configurationsMenu,
    workPlanningMenu,
    fabricationMenu,
  ],
  FIELD: [dashboardMenu, companyFieldMenu, projectsMenu, 'divider', workPlanningFieldMenu],
  DESIGN: [companyFieldMenu, projectsMenu, 'divider', catalogSetupItem, workPlanningFieldMenu],
  MANAGEMENT: [evolveMembersItem, customersItem],
  SYNC: [companySyncMenu, projectsMenu, 'divider', syncMenu],
} as const satisfies Partial<Record<EModuleType, [NavOption, ...(NavOption | 'divider')[]]>>;
