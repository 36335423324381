// Copyright ©️ 2025 eVolve MEP, LLC

import { format, isValid, parseISO } from 'date-fns';

/** `YYYY-MM-DD` */
export type DateOnly = string & { [dateOnlySymbol]: never };
declare const dateOnlySymbol: unique symbol;

/**
 * Converts a `Date` object to a `DateOnly`.
 */
export const dateOnly = (date: Date): DateOnly => format(date, 'yyyy-MM-dd') as DateOnly;

export const isDateOnly = (dateString: string): dateString is DateOnly => {
  if (!/\d{4}-\d{2}-\d{2}/.test(dateString)) return false;
  const dateObj = parseISO(dateString);
  return isValid(dateObj) && dateOnly(dateObj) === dateString;
};

export const today = (): DateOnly => dateOnly(new Date());

/**
 * `YYYY-MM-DDTHH:MM:SS[.MMMMMM]X`
 *
 * where `X` is Timezone
 * */
export type ISOTimestamp = string & { [isoTimestampSymbol]: never };
declare const isoTimestampSymbol: unique symbol;

export const formatDate = (
  date: DateOnly | ISOTimestamp | Date | null | undefined,
  formatStr: Parameters<typeof format>[1],
  fallback = '--',
): string => {
  if (!date) return fallback;
  const dateObj = typeof date === 'object' ? date : parseISO(date);
  if (!isValid(dateObj)) return fallback;
  return format(dateObj, formatStr);
};
