// Copyright ©️ 2025 eVolve MEP, LLC
import { useCallback, useEffect, useState } from 'react';

import {
  ActionIcon,
  Box,
  Button,
  Card,
  Flex,
  getThemeColor,
  Indicator,
  Loader,
  Menu,
  Modal,
  Stack,
  Tabs,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useNavigate, useParams } from 'react-router-dom';

import { ComponentPaneLayout } from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { MESSAGE_PRIORITIES } from 'constants/globalConstants';
import { formatDate } from 'helpers/dateOnly';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { TriggerOnVisible } from 'hooks/useOnScreen';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { TypeOfUser } from 'types/types-api';

import { CreateMessageModal } from './CreateMessageModal';
import { HTMLPreview } from './HTMLPreview';
import { MessagePreview } from './MessagePreview';
import { useMessages } from './MessagesContext';
import type { Message, MessageDetails, MessageId, MessageStatus, PriorityId, TabOption } from './types';

const MessageContents = ({ messageId }: { messageId: MessageId | null }) => {
  const theme = useMantineTheme();
  const [message, setMessage] = useState<MessageDetails>();
  const { getMessageDetails, gettingMessageDetails } = useMessages();
  useEffect(() => {
    if (messageId) {
      void getMessageDetails(messageId).then(setMessage);
    }
  }, [getMessageDetails, messageId]);

  return (
    <Flex align="center" justify="center" style={{ flex: 1 }} h="100%">
      {gettingMessageDetails || isNil(message) ? (
        <Loader size="md" />
      ) : (
        <Stack h="100%" w="100%" p="lg">
          <Text fz={25} fw={600}>
            {message.subject}
          </Text>
          <Text fw={600} c="gray">
            {formatDate(message.createdOn, 'MMMM dd, yyyy')}
          </Text>
          {message.messagePriorityCode === 'HIGH' && (
            <Card
              w="100%"
              p={6}
              withBorder
              bg={`${theme.primaryColor}.0`}
              style={{ borderColor: getThemeColor(theme.primaryColor, theme) }}
            >
              <Text fz="sm">
                <EvolveIcon
                  spanProps={{ style: { marginTop: 2 } }}
                  size="sm"
                  icon="Alert"
                  inline
                  color={theme.primaryColor}
                />{' '}
                This message was sent with high priority.
              </Text>
            </Card>
          )}
          <HTMLPreview html={message.body} />
        </Stack>
      )}
    </Flex>
  );
};

export const MessageCenterPage = () => {
  const [filterMenuOpened, setFilterMenuOpened] = useState(false);
  const [filter, setFilter] = useState<{
    filterName: string;
    messagePriorityId?: PriorityId;
    status?: MessageStatus;
    read?: 'all' | 'unread';
  }>();
  const [currentTab, setCurrentTab] = useState<TabOption>('Messages');
  const [currentlyViewing, setCurrentlyViewing] = useState<MessageId | null>(null);
  const [canCreateMessage, setCanCreateMessage] = useState(false);
  const [createMessage, setCreateMessage] = useState(false);

  const { user } = useUser();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const activeTabColor = getThemeColor(theme.primaryColor, theme);

  const { messageId } = useParams();
  useEffect(() => {
    if (isNotNil(messageId)) {
      setCurrentlyViewing(messageId as MessageId);
      void navigate('/message');
    }
  }, [messageId, navigate]);

  const {
    data: messages,
    entireCount,
    searchHandler,
    reset,
    refetch,
    fetchNextPage,
    loading,
    setDefaultOpts,
  } = useWrappedPaginatedGet<Message>('admin/message', {
    lazy: true,
  });

  useEffect(() => {
    const { filterName: _, ...filterParams } = filter ?? {};
    setDefaultOpts({
      lazy: false,
      perPage: 50,
      defaultConfig: {
        params: {
          read: 'all',
          status: currentTab === 'Messages' ? 'Active Dismissed' : 'Archived',
          ...filterParams,
        },
      },
    });
  }, [currentTab, filter, reset, setDefaultOpts]);

  const { markAllRead, markingRead } = useMessages();

  const { fetchPage: getUserTypes } = useWrappedPaginatedGet<TypeOfUser>('admin/type', {
    lazy: true,
  });
  useEffect(() => {
    void getAllDataFromFetcher(getUserTypes).then((res) => {
      const marketingManagerTypeId = res.find((o) => o.name === 'eVolve Marketing Manager')?.id;
      if (marketingManagerTypeId && user.userTypes.some((o) => o.typeId === marketingManagerTypeId))
        setCanCreateMessage(true);
    });
  }, [getUserTypes, user.userTypes]);

  const doMarkAllRead = useCallback(() => {
    void markAllRead().then(() => {
      notifications.show({
        title: 'Success',
        message: 'All messages marked as read',
        color: 'green',
      });
      void refetch();
    });
  }, [refetch, markAllRead]);

  return (
    <ComponentPaneLayout>
      <>
        <Flex mb="sm" justify="space-between">
          <Button
            variant="outline"
            size="sm"
            leftSection={<EvolveIcon icon="ArrowLeft" size="xs" />}
            onClick={() => navigate(-1)}
          >
            Go back
          </Button>
          {canCreateMessage && (
            <Button size="sm" leftSection={<EvolveIcon icon="Add" size="xs" />} onClick={() => setCreateMessage(true)}>
              Create message
            </Button>
          )}
        </Flex>
        <Flex style={{ height: '100%', overflowY: 'hidden' }}>
          <Card
            withBorder
            display="flex"
            p={0}
            style={{ height: '100%', flexDirection: 'column', overflowY: 'hidden', flex: 1 }}
          >
            <Tabs
              value={currentTab}
              onChange={(e) => {
                setCurrentTab((t) => (e ?? t) as typeof currentTab);
                setCurrentlyViewing(null);
                setFilter(undefined);
              }}
              style={{
                flex: '0 0 auto',
              }}
              styles={{
                list: { flexWrap: 'nowrap' },
                tab: {
                  width: 110,
                  height: 50,
                  fontWeight: 600,
                },
                tabLabel: { fontWeight: 600 },
              }}
            >
              <Tabs.List>
                <Tabs.Tab
                  value="Messages"
                  style={{ flex: 1, color: currentTab === 'Messages' ? activeTabColor : theme.colors.gray[6] }}
                >
                  Messages
                </Tabs.Tab>
                <Tabs.Tab
                  value="Archived"
                  style={{ flex: 1, color: currentTab === 'Archived' ? activeTabColor : theme.colors.gray[6] }}
                >
                  Archived
                </Tabs.Tab>
              </Tabs.List>
            </Tabs>
            <Stack p="xs" style={{ flex: '0 0 auto' }}>
              <Flex justify="space-between" gap="xs" wrap="wrap">
                {currentTab === 'Messages' && (
                  <Button variant="outline" onClick={doMarkAllRead} loading={markingRead}>
                    Mark all as read
                  </Button>
                )}
              </Flex>
              <Flex gap="xs" justify="space-between">
                <TextInputDebounced w="100%" placeholder="Search..." onChange={searchHandler} />
                <Menu
                  position="bottom-end"
                  shadow="sm"
                  opened={filterMenuOpened}
                  onClose={() => setFilterMenuOpened(false)}
                >
                  <AgGridStyleTooltip
                    label={isNotNil(filter) ? `Clear '${filter.filterName}' filter` : 'Filter'}
                    openDelay={100}
                    disabled={filterMenuOpened}
                  >
                    <Menu.Target>
                      <ActionIcon
                        onClick={() => {
                          if (isNotNil(filter)) {
                            setFilter(undefined);
                          } else {
                            setFilterMenuOpened(true);
                          }
                        }}
                        color={isNotNil(filter) ? theme.primaryColor : undefined}
                        size="lg"
                        variant="outline"
                      >
                        <EvolveIcon icon={isNotNil(filter) ? 'ClearFilters' : 'Filter'} />
                      </ActionIcon>
                    </Menu.Target>
                  </AgGridStyleTooltip>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() =>
                        setFilter({
                          filterName: 'High priority',
                          messagePriorityId: MESSAGE_PRIORITIES.HIGH,
                        })
                      }
                    >
                      <Flex align="center" gap={4}>
                        <EvolveIcon icon="Alert" size="sm" color={theme.primaryColor} />
                        <Text>High priority</Text>
                      </Flex>
                    </Menu.Item>
                    {currentTab === 'Messages' && (
                      <Menu.Item
                        onClick={() =>
                          setFilter({
                            filterName: 'Unread',
                            read: 'unread',
                          })
                        }
                      >
                        <Flex align="center" gap={4}>
                          <Indicator position="top-center">
                            <Box w={18} />
                          </Indicator>
                          <Text>Unread</Text>
                        </Flex>
                      </Menu.Item>
                    )}
                  </Menu.Dropdown>
                </Menu>
              </Flex>
            </Stack>
            <Flex direction="column" py="xs" gap="sm" px="md" style={{ overflowY: 'scroll' }}>
              {messages.map((m) => (
                <MessagePreview
                  key={m.messageId}
                  message={m}
                  canArchive={currentTab !== 'Archived'}
                  currentlyViewing={currentlyViewing}
                  setCurrentlyViewing={setCurrentlyViewing}
                  refreshAllMessages={refetch}
                />
              ))}
              {entireCount === 0 && (
                <Text c="dimmed" fz="sm">
                  No messages matching current filter.
                </Text>
              )}
              <TriggerOnVisible loading={loading} onVisible={fetchNextPage} />
            </Flex>
          </Card>
          <Card withBorder style={{ flex: 3 }} h="100%" ml={5}>
            {currentlyViewing ? (
              <MessageContents messageId={currentlyViewing} />
            ) : (
              <Flex justify="center" align="center" h="100%">
                {loading ? (
                  <Loader />
                ) : (
                  <Text fs="italic" c="gray">
                    {messages.length > 0 ? 'Select a message to view' : 'No messages to view'}
                  </Text>
                )}
              </Flex>
            )}
          </Card>
        </Flex>

        <Modal
          size="lg"
          opened={createMessage}
          onClose={() => setCreateMessage(false)}
          centered
          padding="md"
          closeOnClickOutside={false}
          closeOnEscape={false}
        >
          <CreateMessageModal closeModal={() => setCreateMessage(false)} />
        </Modal>
      </>
    </ComponentPaneLayout>
  );
};
