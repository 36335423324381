// Copyright ©️ 2025 eVolve MEP, LLC

import React, { type ReactNode } from 'react';

import { LoadingOverlay } from '@mantine/core';
import { Navigate } from 'react-router-dom';

import { isNil } from 'helpers/isNotNil';
import { useGeneralContext } from 'helpers/useGeneralContext';
import type { User } from 'types/types-api';

import { ProfilePictureProvider } from './Layout/HeaderBar/ProfileDropdown/ProfilePictureContext';
import { useMaybeUser } from './MaybeUserContext';

type UserContextType = {
  user: User;
  setUser: (user: User | null) => void;
  loading: boolean;
  refreshUser: () => Promise<User | null>;
};

const UserContext = React.createContext<UserContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const UserProvider = ({ children }: Props) => {
  const context = useMaybeUser();

  if (isNil(context.user)) {
    if (context.loading) {
      return <LoadingOverlay visible />;
    }
    const path = window.location.pathname;
    const redirect = path !== '/' ? `?redirect=${path}` : '';
    return <Navigate to={`/auth/login${redirect}`} />;
  }

  return (
    <UserContext.Provider
      value={
        // Cast is safe due to isNil(context.user)
        context as UserContextType
      }
    >
      <ProfilePictureProvider>{children}</ProfilePictureProvider>
    </UserContext.Provider>
  );
};

export const useUser = () => useGeneralContext(UserContext, 'User');
