// Copyright ©️ 2025 eVolve MEP, LLC

import { useState } from 'react';

import { ActionIcon, Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNil } from 'helpers/isNotNil';
import { useWrappedPatch } from 'hooks-api/useWrappedApiCall';

import type { CompanyMember } from './types';

export const companyMembersBulkActions = [
  'Assign license',
  'Assign role',
  'Remove role',
  'Assign admin',
  'Remove admin',
  'Reset password',
  'Resend invite',
  'Delete user',
] as const;
export type CompanyMembersBulkAction = (typeof companyMembersBulkActions)[number];

type Props = {
  user: CompanyMember;
  actionSingleUser: (user: CompanyMember, selectedAction: CompanyMembersBulkAction) => void;
  hasMechanical: boolean;
  hasElectrical: boolean;
};

export const CompanyMemberMenu = ({ user, actionSingleUser, hasMechanical, hasElectrical }: Props) => {
  const [opened, setOpened] = useState(false);
  const { user: myUser } = useUser();
  const { apiCall: resendInvitation, loading } = useWrappedPatch(`admin/user/${user.userId}/resendInvitation`);
  return (
    <Menu position="bottom-end" withinPortal opened={opened} onChange={setOpened}>
      <Menu.Target>
        <ActionIcon variant="transparent">
          <EvolveIcon icon="MoreOptsVert" className={opened ? undefined : 'show-on-row-hover'} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {companyMembersBulkActions.map((action) =>
          action === 'Resend invite' && user.status !== 'Pending' ? null : (
            <Menu.Item
              key={action}
              color={action === 'Delete user' ? 'red' : undefined}
              disabled={
                // users can't delete or remove admin tag on themselves
                ((action === 'Delete user' || action === 'Remove admin') && user.userId === myUser.userId) ||
                // don't allow a password reset when the user status is still 'Pending'
                (action === 'Reset password' && user.status === 'Pending') ||
                // you can't remove admin tag from someone who isn't already an admin
                (action === 'Remove admin' && !user.isAdmin) ||
                // you can't make someone an admin who is already an admin
                (action === 'Assign admin' && user.isAdmin) ||
                // you can't remove roles from someone who has no roles assigned to them
                (action === 'Remove role' && isNil(user.electricalRoleId) && isNil(user.mechanicalRoleId)) ||
                // you can't remove roles from a user who is currently an admin
                (action === 'Remove role' && user.isAdmin) ||
                // you can't assign roles when the company doesn't have either eE or eM
                (action === 'Assign role' && !hasMechanical && !hasElectrical) ||
                // you can't assign roles to a user who is currently an admin
                (action === 'Assign role' && user.isAdmin) ||
                // resend invite should be disabled while the action of resending is in progress
                (action === 'Resend invite' && loading)
              }
              onClick={() =>
                action === 'Resend invite'
                  ? resendInvitation({}).then(() => {
                      notifications.show({
                        title: 'Successfully sent',
                        message: `Invitation resent to ${user.email}`,
                        color: 'green',
                      });
                    })
                  : actionSingleUser(user, action)
              }
            >
              {action}
            </Menu.Item>
          ),
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
