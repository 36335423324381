// Copyright ©️ 2025 eVolve MEP, LLC

import { Link, type LinkProps, useNavigate } from 'react-router-dom';

import type { StrongOmit } from 'helpers/helper-types';
import { isNotNil } from 'helpers/isNotNil';

import { getHistory } from './PageBreadcrumb';
import type { PageTitle } from './types';
import { useEvolveLocation } from './useEvolveLocation';
import { calculateNewState } from './useEvolveNavigate';

type Props = (
  | {
      from: PageTitle | string;
      clearHistory?: boolean;
    }
  | {
      from?: PageTitle;
      clearHistory: true;
    }
) &
  // State is managed internally by the EvolveLink
  StrongOmit<LinkProps, 'state'>;

export const EvolveLink = ({ to, children, onClick, ...props }: Props) => {
  const navigate = useNavigate();
  const { state } = useEvolveLocation();
  const history = getHistory(state);
  const historyIndexOfToLoc = history.find((h) => h.url === to)?.index;
  const newState = calculateNewState({
    to,
    state,
    ...props,
  });
  return (
    <Link
      className="link"
      to={to}
      state={newState}
      onClick={(e) => {
        onClick?.(e);
        if (isNotNil(historyIndexOfToLoc)) {
          e.preventDefault();
          void navigate(historyIndexOfToLoc - history.length);
        }
        e.stopPropagation();
      }}
      {...props}
    >
      {children}
    </Link>
  );
};
