// Copyright ©️ 2025 eVolve MEP, LLC

import type { ReactNode } from 'react';

import { Flex, type MantineSpacing } from '@mantine/core';

import { isNotNil } from 'helpers/isNotNil';

import { ChangeTableView, type ChangeTableViewProps } from './ChangeTableView/ChangeTableView';
import { ClearFiltersButton, type ClearFiltersButtonProps } from './ClearFiltersButton';
import { PageBreadcrumb, type PageBreadcrumbProps } from './Navigation/PageBreadcrumb';
import { OpenClosedTabs, type OpenClosedTabsProps } from './OpenClosedTab';

type Props = {
  spacing?: MantineSpacing;
  topLeftComponent?: ReactNode;
  topRightComponent?: ReactNode;
  bottomLeftComponent?: ReactNode;
  bottomRightComponent?: ReactNode;
} & PageBreadcrumbProps &
  Partial<OpenClosedTabsProps> &
  Partial<ClearFiltersButtonProps> &
  Partial<ChangeTableViewProps>;

export const BasePageHeader = ({
  spacing = 'xs',
  gridRef,
  filterIsSet,
  onFilterClear,
  showOpen,
  setShowOpen,
  viewsToShow,
  topLeftComponent,
  topRightComponent,
  bottomLeftComponent,
  bottomRightComponent,
  ...props
}: Props) => (
  <>
    <Flex justify="space-between" align="center" mb={spacing} gap="lg" wrap="wrap">
      <Flex align="center" gap="lg">
        <PageBreadcrumb {...props} />
        {isNotNil(showOpen) && isNotNil(setShowOpen) && (
          <OpenClosedTabs showOpen={showOpen} setShowOpen={setShowOpen} />
        )}
        {topLeftComponent}
      </Flex>
      <Flex align="center" gap="md" ml="auto">
        {topRightComponent}
        {isNotNil(viewsToShow) && <ChangeTableView viewsToShow={viewsToShow} />}
      </Flex>
    </Flex>
    <Flex justify="space-between" align="flex-end" mb="xs" gap="lg" wrap="wrap">
      <Flex align="center" gap="md">
        {bottomLeftComponent}
      </Flex>
      <Flex align="center" gap="md" ml="auto">
        {isNotNil(gridRef) && (
          <ClearFiltersButton gridRef={gridRef} filterIsSet={filterIsSet} onFilterClear={onFilterClear} />
        )}
        {bottomRightComponent}
      </Flex>
    </Flex>
  </>
);
