// Copyright ©️ 2025 eVolve MEP, LLC
import { Flex } from '@mantine/core';
import type { ColDef, GridApi, SelectionColumnDef } from 'ag-grid-enterprise';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { DeselectAllCheckbox } from 'helpers/ag-grid/DeselectAllCheckbox';
import { isNil } from 'helpers/isNotNil';
import { WRITE_IN_ASSEMBLY } from 'modules/Field/WorkRequests/WorkRequest/constants';
import type { WorkRequestItem } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type { UnitOfMeasure } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';

import { ItemsMenu } from './ItemsMenu';
import { StatusPoint } from './StatusPoint';
import type { WorkOrderItem } from './types';
import { useWorkRequestOrderItems } from './WorkRequestOrderItemsContext';

const HeaderCheckbox = ({ api }: { api: GridApi }) => {
  const context = useWorkRequestOrderItems();
  const enabled =
    ('selectedWorkRequestItems' in context ? context.selectedWorkRequestItems : context.selectedWorkOrderItems).length >
    0;
  return <DeselectAllCheckbox api={api} enabled={enabled} />;
};

export const deselectAllColDef: SelectionColumnDef = {
  ...lockedColDef(),
  headerComponent: HeaderCheckbox,
  width: 48,
};

export const getWorkOrderRequestItemColumnDef = <T extends WorkRequestItem | WorkOrderItem>({
  nameKey,
  isInProgress,
  isCompleted,
  canEditItems,
  canEditTasks,
  uoms,
}: {
  nameKey: keyof T;
  isInProgress: boolean;
  isCompleted: boolean;
  canEditItems: boolean;
  canEditTasks: boolean;
  uoms: UnitOfMeasure[] | undefined;
}): ColDef<T>[] => [
  {
    ...lockedColDef(),
    cellRenderer: CellRenderer<T>(
      ({ data }) => (
        <Flex align="center" style={{ height: '100%' }}>
          <StatusPoint
            status={
              'workRequestItemStatusTypeId' in data ? data.workRequestItemStatusTypeId : data.workOrderItemStatusTypeId
            }
          />
        </Flex>
      ),
      { show: ({ data }) => data.hasShopTask },
    ),
    width: 40,
    hide: !isInProgress,
  },
  // {
  //   ...lockedColDef(),
  //   headerComponent: HeaderCheckbox,
  //   width: 48,
  //   hide: !canEditItems,
  //   // TODO: Uncomment when Inherit from BOP is enabled
  //   // hide: !canEditItems && !canEditTasks,
  // },
  {
    ...lockedColDef(),
    cellRenderer: 'agGroupCellRenderer',
    width: 48,
  },
  {
    // @ts-expect-error TypeScript can't verify this, but it's fine
    field: nameKey,
    headerName: 'Item Name',
    valueGetter: ({ data }) => {
      if (isNil(data)) return null;
      // Defined such that the cell only flashes when one of these two fields change
      return 'workRequestItemTypeId' in data
        ? `${data.workRequestItemTypeId}-${data.workRequestItemName}`
        : `${data.workOrderItemTypeId}-${data.workOrderItemName}`;
    },
    valueFormatter: ({ data }) => {
      if (isNil(data)) return '';
      return 'workRequestItemTypeId' in data ? data.workRequestItemName : data.workOrderItemName;
    },
    tooltipValueGetter: ({ data }) => {
      if (isNil(data)) return null;
      return 'workRequestItemName' in data ? data.workRequestItemName : data.workOrderItemName;
    },
    cellRenderer: CellRenderer<T>(({ data, valueFormatted }) => (
      <>
        <EvolveIcon
          icon={
            ('workRequestItemTypeId' in data ? data.workRequestItemTypeId : data.workOrderItemTypeId) ===
            WRITE_IN_ASSEMBLY
              ? 'WriteInItem'
              : 'Assembly'
          }
          light
        />
        {valueFormatted}
        {data.hasDecisionsToMake && (
          <AgGridStyleTooltip label="Unmade decisions" withArrow openDelay={0}>
            <EvolveIcon color="orange.4" icon="Info" size="sm" />
          </AgGridStyleTooltip>
        )}
      </>
    )),
    flex: 1,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    headerName: 'Qty',
    valueGetter: ({ data }) => data?.quantity,
    sortable: false,
    width: 80,
  },
  {
    headerName: 'Unit Qty',
    valueGetter: ({ data }) => data?.unityQuantity,
    sortable: false,
    width: 100,
    // Using || instead of ?? as we want to render 0 as empty string
    valueFormatter: ({ value }) => value || '',
  },
  {
    headerName: 'UOM',
    valueGetter: ({ data }) => uoms?.find((u) => u.unitOfMeasureId === data?.unitOfMeasureId)?.unitOfMeasureCode ?? '',
    sortable: false,
    width: 100,
  },
  {
    ...lockedColDef('right'),
    width: 48,
    cellRenderer: CellRenderer<T>(({ api, node }) => (
      <ItemsMenu
        canEditItem={canEditItems}
        canEditTasks={canEditTasks}
        parentIsCompleted={isCompleted}
        refresh={() => api.refreshServerSide({ purge: true })}
        {...('workOrderItemId' in node.data
          ? {
              workOrderItem: node.data,
              workOrderItemUpdated: (d) => node.updateData(d as T),
            }
          : {
              workRequestItem: node.data,
              workRequestItemUpdated: (d) => node.updateData(d as T),
            })}
      />
    )),
  },
];
