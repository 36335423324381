// Copyright ©️ 2025 eVolve MEP, LLC

import { useEffect, useMemo } from 'react';

import { AppShell, LoadingOverlay } from '@mantine/core';
import { Outlet, Navigate } from 'react-router-dom';

import { EULAModal } from 'app/EULAModal';
import { useModule } from 'app/Modules/ModuleContext';
import { useUser } from 'app/UserContext';
import { PRODUCT_IDS } from 'constants/globalConstants';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useSetupModule } from 'hooks/useSetupModule';
import { EModuleType } from 'types/module';

import { HeaderBar } from './HeaderBar/HeaderBar';
import { DesktopNavBar } from './NavBar/DesktopNavBar';

const electricalOrMechanicalProducts = [PRODUCT_IDS.ELECTRICAL_LICENSE, PRODUCT_IDS.MECHANICAL_LICENSE];

export const Layout = () => {
  const { user, refreshUser } = useUser();
  const { setSelectedModule, module, currentPage } = useSetupModule();
  const { modules, entitlements, loading } = useModule();

  const moduleIsEnabled = useMemo(() => isNotNil(module) && modules?.includes(module), [modules, module]);

  const electricalOrMechanicalLicense = useMemo(
    () => entitlements?.some((e) => electricalOrMechanicalProducts.includes(e.productPool.productId)),
    [entitlements],
  );

  useEffect(() => {
    if (isNotNil(modules) && modules.length > 0 && !module) setSelectedModule(modules[0]);
  }, [modules, setSelectedModule, module]);

  useEffect(() => {
    // If, somehow, the "selected" module isn't one of the available modules,
    // just default to their first available module
    if (!moduleIsEnabled && currentPage() !== EModuleType.MESSAGE && isNotNil(modules) && modules.length > 0) {
      setSelectedModule(modules[0]);
    }
  }, [currentPage, moduleIsEnabled, modules, setSelectedModule]);

  if (loading || isNil(modules) || isNil(entitlements)) return <LoadingOverlay visible />;

  // All users should have access to the Messages page,
  // even if they have access to no modules or licenses.
  if (currentPage() !== EModuleType.MESSAGE && modules.length === 0) {
    if (electricalOrMechanicalLicense) {
      return <Navigate to="reset-password-landing" />;
    }
    if (!moduleIsEnabled) {
      return <Navigate to="/no-licenses" />;
    }
  }

  return (
    <>
      <HeaderBar />
      <DesktopNavBar />
      <AppShell.Main display="flex" style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Outlet />
      </AppShell.Main>
      {!user.acceptedLatestEula && (
        <EULAModal userName={user.userName} onEulaAccepted={refreshUser} onClose={refreshUser} />
      )}
    </>
  );
};
