// Copyright ©️ 2025 eVolve MEP, LLC
import React, { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useUser } from 'app/UserContext';
import { formatDate, today } from 'helpers/dateOnly';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useGeneralContext } from 'helpers/useGeneralContext';
import type { Facility } from 'hooks/projectsAndFacilities/useFacilities';
import type { Project } from 'hooks/projectsAndFacilities/useProjects';
import { useLocalStorage } from 'hooks/useLocalStorage';
import {
  type EvolveApiReturn,
  useWrappedGet,
  useWrappedPaginatedGet,
  useWrappedPost,
} from 'hooks-api/useWrappedApiCall';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';

import type {
  WorkOrdersOverTimeRes,
  InsightsReq,
  WorkRequestsOverTimeRes,
  RecordOption,
  TimeframeOption,
  InsightsContextType,
  InsightsDetailsWorkRequest,
  InsightsDetailsWorkOrder,
} from './types';

const InsightsContext = React.createContext<InsightsContextType | undefined>(undefined);

export const InsightsProvider = ({ children }: { children: ReactNode }) => {
  const { apiCall: getWorkRequestsOverTime, loading: wrLoading } = useWrappedPost<WorkRequestsOverTimeRes, InsightsReq>(
    'shop/insights/workrequestsovertime',
  );
  const { apiCall: getWorkOrdersOverTime, loading: woLoading } = useWrappedPost<WorkOrdersOverTimeRes, InsightsReq>(
    'shop/insights/workordersovertime',
  );
  const { apiCall: getWorkRequestsDetail, loading: wrDetailLoading } = useWrappedPost<
    EvolveApiReturn<InsightsDetailsWorkRequest>,
    InsightsReq
  >('shop/insights/workrequestsovertimedetail');
  const { apiCall: getWorkOrdersDetail, loading: woDetailLoading } = useWrappedPost<
    EvolveApiReturn<InsightsDetailsWorkOrder>,
    InsightsReq
  >('shop/insights/workordersovertimedetail');
  const { apiCall: getRecordTypes, loading: loadingRecordTypes } = useWrappedGet<{ insightTypes: RecordOption[] }>(
    'shop/insights/types',
  );
  const { fetchPage: fetchFacilities, loading: facilityLoading } = useWrappedPaginatedGet<Facility>('admin/facility', {
    lazy: true,
  });
  const { fetchPage: fetchProjects, loading: projectsLoading } = useWrappedPaginatedGet<Project>('admin/project', {
    lazy: true,
  });
  const loading =
    wrLoading ||
    woLoading ||
    wrDetailLoading ||
    woDetailLoading ||
    loadingRecordTypes ||
    facilityLoading ||
    projectsLoading;

  const { user } = useUser();
  const [params, setParams] = useLocalStorage('INSIGHTS_FILTERS', {
    facilities: ['All Facilities'],
    projects: ['All Projects'],
    timeFrame: 'This Year',
    selectedRecordType: null,
    statuses: ['All Statuses'],
  });
  const [detailedReport, setDetailedReport] = useLocalStorage('INSIGHTS_DETAILED', false);
  const [facilities, setFacilities] = useState<Facility[] | null>(null);
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [selectedFacilities, setSelectedFacilities] = useState<InsightsReq['facilities']>(params.facilities);
  const [selectedProjects, setSelectedProjects] = useState<InsightsReq['projects']>(params.projects);
  const [selectedRecordType, setSelectedRecordType] = useState<RecordOption | null>(params.selectedRecordType);
  const [recordTypes, setRecordTypes] = useState<RecordOption[] | null>(
    selectedRecordType ? [selectedRecordType] : null,
  );
  const [selectedTimeframe, setSelectedTimeframe] = useState<TimeframeOption | null>(params.timeFrame);
  const [date, setDate] = useState<Date | null>(params.specificDate ?? null);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    params.beginDate ?? null,
    params.endDate ?? null,
  ]);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [displayedData, setDisplayedData] = useState<(WorkRequestsOverTimeRes | WorkOrdersOverTimeRes) | null>(null);
  const [wrDetailedData, setWrDetailedData] = useState<InsightsDetailsWorkRequest[] | null>(null);
  const [woDetailedData, setWoDetailedData] = useState<InsightsDetailsWorkOrder[] | null>(null);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [showSelectedDates, setShowSelectedDates] = useState<boolean>(true);
  const [selectedStatuses, setSelectedStatuses] = useState<InsightsReq['statuses']>(params.statuses);

  const fetchData = useCallback(() => {
    const { selectedRecordType: recordType, ...remainingParams } = params;
    if (remainingParams.facilities.length && remainingParams.projects.length && remainingParams.timeFrame) {
      setWrDetailedData(null);
      setWoDetailedData(null);
      setDisplayedData(null);
      if (recordType === 'Work Requests') {
        if (detailedReport) {
          void getWorkRequestsDetail({ ...remainingParams, currentDate: today() }).then((res) => {
            setWrDetailedData(res.data);
            setLastUpdated(formatDate(new Date(), 'PPPppp'));
          });
        } else {
          void getWorkRequestsOverTime({ ...remainingParams, currentDate: today() }).then((res) => {
            setDisplayedData(res);
            setLastUpdated(formatDate(new Date(), 'PPPppp'));
          });
        }
      }
      if (recordType === 'Work Orders') {
        if (detailedReport) {
          void getWorkOrdersDetail({ ...remainingParams, currentDate: today() }).then((res) => {
            setWoDetailedData(res.data);
            setLastUpdated(formatDate(new Date(), 'PPPppp'));
          });
        } else {
          void getWorkOrdersOverTime({ ...remainingParams, currentDate: today() }).then((res) => {
            setDisplayedData(res);
            setLastUpdated(formatDate(new Date(), 'PPPppp'));
          });
        }
      }
    }
  }, [
    params,
    detailedReport,
    getWorkRequestsDetail,
    getWorkRequestsOverTime,
    getWorkOrdersDetail,
    getWorkOrdersOverTime,
  ]);

  const generateReqData = useCallback(() => {
    if (!showDatePicker) {
      setParams({
        facilities: selectedFacilities,
        projects: selectedProjects,
        timeFrame: selectedTimeframe,
        selectedRecordType,
        beginDate: dateRange[0] ?? undefined,
        endDate: dateRange[1] ?? undefined,
        specificDate: date ?? undefined,
        statuses: selectedStatuses,
      });
    }
  }, [
    showDatePicker,
    setParams,
    selectedFacilities,
    selectedProjects,
    selectedTimeframe,
    selectedRecordType,
    dateRange,
    date,
    selectedStatuses,
  ]);

  useEffect(() => {
    if (selectedRecordType !== params.selectedRecordType) {
      // when user changes record type in drop down, reset all
      // filters to default values and refetch
      setSelectedFacilities(['All Facilities']);
      setSelectedProjects(['All Projects']);
      setSelectedTimeframe('This Year');
      setSelectedStatuses(['All Statuses']);
      setParams({
        projects: ['All Projects'],
        facilities: ['All Facilities'],
        statuses: ['All Statuses'],
        timeFrame: 'This Year',
        selectedRecordType,
      });
    }
  }, [params.selectedRecordType, selectedRecordType, setParams]);

  useEffect(() => {
    void getAllDataFromFetcher(fetchFacilities, {
      params: {
        companyId: user.companyId,
        showAvailableFacilities: true,
        departmentTypeIds: DEPARTMENT_TYPE.SHOP,
      },
    }).then(setFacilities);
  }, [fetchFacilities, user.companyId]);

  useEffect(() => {
    void getAllDataFromFetcher(fetchProjects, {
      params: {
        companyId: user.companyId,
        showAllProjects: true,
      },
    }).then(setProjects);
  }, [fetchProjects, user.companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!selectedProjects.length) {
      setSelectedProjects(['All Projects']);
    }
    if (!selectedFacilities.length) {
      setSelectedFacilities(['All Facilities']);
    }
    if (!selectedStatuses.length) {
      setSelectedStatuses(['All Statuses']);
    }
  }, [selectedProjects, selectedFacilities, selectedStatuses]);

  useEffect(() => {
    if (selectedTimeframe !== 'Date Range') {
      setDateRange([null, null]);
    }
    if (selectedTimeframe !== 'Specific Date') {
      setDate(null);
    }
  }, [selectedTimeframe]);

  useEffect(() => {
    void getRecordTypes().then((res) => {
      setRecordTypes(res.insightTypes);
    });
  }, [getRecordTypes, selectedRecordType, setParams]);

  useEffect(() => {
    if (isNil(selectedRecordType) && isNotNil(recordTypes)) {
      if (recordTypes.includes('Work Orders')) {
        setSelectedRecordType('Work Orders');
        setParams((prev) => ({
          ...prev,
          selectedRecordType: 'Work Orders',
        }));
      } else {
        setSelectedRecordType('Work Requests');
        setParams((prev) => ({
          ...prev,
          selectedRecordType: 'Work Requests',
        }));
      }
    }
  }, [recordTypes, selectedRecordType, setParams]);

  const value = useMemo(
    () => ({
      loading,
      generateReqData,
      lastUpdated,
      displayedData,
      wrDetailedData,
      setWrDetailedData,
      woDetailedData,
      setWoDetailedData,
      setSelectedRecordType,
      selectedRecordType,
      setSelectedTimeframe,
      showDatePicker,
      setShowDatePicker,
      showSelectedDates,
      setShowSelectedDates,
      detailedReport,
      setDetailedReport,
      selectedFacilities,
      setSelectedFacilities,
      selectedTimeframe,
      setDate,
      date,
      setDateRange,
      dateRange,
      selectedProjects,
      setSelectedProjects,
      selectedStatuses,
      setSelectedStatuses,
      params,
      recordTypes,
      facilities,
      projects,
    }),
    [
      loading,
      generateReqData,
      lastUpdated,
      displayedData,
      wrDetailedData,
      woDetailedData,
      selectedRecordType,
      showDatePicker,
      showSelectedDates,
      detailedReport,
      setDetailedReport,
      selectedFacilities,
      selectedTimeframe,
      date,
      dateRange,
      selectedProjects,
      selectedStatuses,
      params,
      recordTypes,
      facilities,
      projects,
    ],
  );

  return <InsightsContext.Provider value={value}>{children}</InsightsContext.Provider>;
};

export const useInsights = () => useGeneralContext(InsightsContext, 'Insights');
