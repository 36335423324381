// Copyright ©️ 2025 eVolve MEP, LLC

import React, { forwardRef } from 'react';

import { useMantineTheme, type MantineColor, type MantineSize, getThemeColor } from '@mantine/core';

import { isNil } from 'helpers/isNotNil';

import { iconMap } from './icons';

export type EvolveIconName = keyof typeof iconMap;

const iconSizing: Record<MantineSize, number> = {
  xs: 12,
  sm: 18,
  md: 24,
  lg: 32,
  xl: 40,
} as const;

type Props = {
  /**
   * If the passed in icon name is null,
   * nothing will be rendered
   */
  icon: EvolveIconName | null;
  className?: string;
  inline?: boolean;
  spanProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
} & (
  | {
      size?: MantineSize;
      width?: never;
      height?: never;
    }
  | {
      size?: never;
      width?: number;
      height?: number;
    }
) &
  (
    | {
        light?: boolean;
        color?: never;
      }
    | {
        light?: never;
        color?: MantineColor;
      }
  );

export const EvolveIcon = forwardRef<HTMLDivElement, Props>(
  ({ icon, spanProps, inline = false, color = 'inherit', light, size = 'md', width, height, ...props }, ref) => {
    const theme = useMantineTheme();
    const sizing = {
      width: width ?? iconSizing[size],
      height: height ?? width ?? iconSizing[size],
    };
    if (isNil(icon)) return null;
    const Icon = iconMap[icon];
    return (
      <span
        ref={ref}
        {...spanProps}
        style={{
          ...sizing,
          display: inline ? 'inline-flex' : 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          verticalAlign: inline ? 'top' : undefined,
          ...spanProps?.style,
        }}
      >
        <Icon color={getThemeColor(light ? 'gray.6' : color, theme)} {...sizing} {...props} />
      </span>
    );
  },
);
