// Copyright ©️ 2025 eVolve MEP, LLC

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Flex, Loader, Popover, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { AgGridReact } from 'ag-grid-react';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { WrappedMultiSelect, WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { PRODUCT_IDS } from 'constants/globalConstants';
import { getMultiRowSelection } from 'helpers/ag-grid/baseColumnDef';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { extractErrorMessage } from 'helpers/extractError';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPaginatedGet, useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import { CompanyDocument } from 'modules/Admin/CompanyMembers/CompanyDocument';
import { InviteUsersModal } from 'modules/Shop/ShopMembers/ShopMembersMantine/InviteUsersModal';
import type { Entitlement, ProductPool, ProductPoolId, User, UserId } from 'types/types-api';

import { AddLicensesModal } from './AddLicensesModal';
import { AssignRolesModal } from './AssignRolesModal';
import { getCompanyMembersColDef, getEntitlementsColDef } from './columnDefs';
import { type CompanyMembersBulkAction, companyMembersBulkActions } from './CompanyMemberMenu';
import { RoleNamesModal } from './RoleNamesModal';
import type { AssignableRole, CompanyMember, CompanyMembersReq, RoleEntry, RoleToRemove } from './types';
import { ViewPermissionsModal } from './ViewPermissionsModal';

const { MECHANICAL_LICENSE, ELECTRICAL_LICENSE } = PRODUCT_IDS;

export const CompanyMembersPage = () => {
  const { user } = useUser();
  const { apiCall: sendPasswordResetEmail } = useWrappedPost('admin/authentication/forgotPassword', {
    dontAlertOnError: true,
  });
  const [showNoLicense, setShowNoLicense] = useState(false);
  const [selectedProductPools, setSelectedProductPools] = useState<ProductPoolId[]>([]);
  const [singleUserAction, setSingleUserAction] = useState<CompanyMember | null>(null);
  const [removeRole, setRemoveRole] = useState<RoleToRemove | null>(null);
  const [selectedRows, setSelectedRows] = useState<CompanyMember[]>([]);
  const [selectedAction, setSelectedAction] = useState<CompanyMembersBulkAction | null>(null);
  const [configureRoleNames, setConfigureRoleNames] = useState(false);
  const [viewPermissions, setViewPermissions] = useState(false);
  const [productPools, setProductPools] = useState<ProductPool[]>([]);

  const hasElectrical = useMemo<boolean>(
    () => productPools.some((p) => p.productId === ELECTRICAL_LICENSE),
    [productPools],
  );
  const hasMechanical = useMemo<boolean>(
    () => productPools.some((p) => p.productId === MECHANICAL_LICENSE),
    [productPools],
  );

  const { fetchPage: fetchProductPools, loading: loadingProductPools } = useWrappedPaginatedGet<ProductPool>(
    'admin/productPool',
    { lazy: true },
  );
  useEffect(() => {
    void getAllDataFromFetcher(fetchProductPools, {
      params: {
        companyId: user.companyId,
        excludeExpired: true,
      },
    }).then(setProductPools);
  }, [fetchProductPools, user]);

  const { apiCall: doRemoveRole, loading: removingRole } = useWrappedPost<
    unknown,
    { userRoleAssignments: RoleEntry[] }
  >('admin/role/remove');

  const { apiCall: addOrRemoveAdmin, loading: addingOrRemoving } = useWrappedPatch<User, { isAdmin: boolean }>(
    'admin/user/:userId',
  );

  const { apiCall: deleteUsersApiCall, loading: deleting } = useWrappedPatch<unknown, { userIds: UserId[] }>(
    'admin/user/multidelete',
  );
  const deleteUsers = useCallback(
    async (userIds: UserId[]) => {
      await deleteUsersApiCall({ userIds }).then(() => {
        notifications.show({
          title: 'Successfully deleted',
          message: `Deleted ${userIds.length} user${userIds.length === 1 ? '' : 's'}`,
          color: 'green',
        });
      });
    },
    [deleteUsersApiCall],
  );
  const [searchPhrase, setSearchPhrase] = useState('');
  const { fetchPage, setDefaultOpts, searchHandler } = useWrappedPaginatedGet<CompanyMember, CompanyMembersReq>(
    'admin/Company/members',
    {
      lazy: true,
    },
  );
  useEffect(() => {
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          companyId: user.companyId,
          productPoolId: selectedProductPools.join(','),
          hasLicense: showNoLicense ? false : undefined,
        },
      },
    });
  }, [setDefaultOpts, selectedProductPools, showNoLicense, user.companyId]);
  const { fetchPage: fetchEntitlementPage } = useWrappedPaginatedGet<Entitlement>('admin/entitlement/multisearch');

  const { data: assignableRoles, apiCall: fetchAssignableRoles } =
    useWrappedGet<AssignableRole[]>('admin/Role/assignable');

  const actionSingleUser = useCallback((selectedUser: CompanyMember, action: CompanyMembersBulkAction) => {
    setSelectedAction(action);
    setSingleUserAction(selectedUser);
  }, []);
  const usersToAction = useMemo(
    () => (singleUserAction ? [singleUserAction] : selectedRows),
    [selectedRows, singleUserAction],
  );
  useEffect(() => {
    if (isNil(selectedAction)) {
      setSingleUserAction(null);
    }
  }, [selectedAction]);

  useEffect(() => {
    if (selectedAction === 'Reset password') {
      setSelectedAction(null);
      void Promise.allSettled(
        usersToAction.map(({ email }) =>
          sendPasswordResetEmail({}, { params: { email } }).catch((error) => {
            notifications.show({
              title: `Error (${email})`,
              // AWS errors tend to have the useful error on error.message
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              message: error?.message ?? extractErrorMessage(error),
              color: 'red',
            });
            throw error;
          }),
        ),
      ).then((results) => {
        const sent = results.filter((r) => r.status === 'fulfilled').length;
        if (sent > 0) {
          notifications.show({
            title: 'Successfully sent',
            message: `${sent} password reset email${sent === 1 ? '' : 's'} sent`,
            color: 'green',
          });
        }
      });
    }
  }, [selectedAction, sendPasswordResetEmail, usersToAction]);

  const colDef = useMemo(
    () =>
      getCompanyMembersColDef({
        hasDetailTable: true,
        actionSingleUser,
        filterOnStatus: true,
        includeSearch: true,
        roles: assignableRoles ?? [],
        setRemoveRole,
        hasElectrical,
        hasMechanical,
      }),
    [actionSingleUser, assignableRoles, hasElectrical, hasMechanical],
  );

  const entitlementsColDef = useMemo(() => getEntitlementsColDef(), []);

  const licensesDetailTable = [
    {
      title: 'Licenses',
      colDef: entitlementsColDef,
      hideHeader: true,
      fetchPage: fetchEntitlementPage,
      icon: null,
      autoExpand: true,
      configMapper: (d: CompanyMember) => ({ params: { userId: d.userId } }),
    },
  ];

  const { agGridProps, expandDetailGrid, refreshDetailGrid, refreshGrid, filterIsSet } = useServerSideGrid({
    tableName: 'company-members',
    fetchPage,
    colDef,
    rowId: 'userId',
    detailTables: licensesDetailTable,
  });

  const clearSelection = useCallback(() => {
    agGridProps.ref.current?.api.deselectAll();
  }, [agGridProps.ref]);

  const defaultColDef = useMemo<(typeof agGridProps)['defaultColDef']>(
    () => ({
      ...agGridProps.defaultColDef,
      cellClassRules: {
        'disabled-row': (r) => r.data?.status === 'Deleted',
      },
    }),
    [agGridProps.defaultColDef],
  );

  const deleteOrRemoveDisabled = useMemo(
    () => selectedRows.some((r) => r.userId === user.userId),
    [selectedRows, user.userId],
  );

  const rowSelection = useMemo(
    () =>
      getMultiRowSelection<CompanyMember>({
        checkboxes: (d) => d.data?.status !== 'Deleted',
      }),
    [],
  );

  const dropdownData: { value: CompanyMembersBulkAction; label: string; disabled: boolean }[] = useMemo(
    () =>
      companyMembersBulkActions
        .filter((a) => a !== 'Remove role' && a !== 'Resend invite')
        .map((action: CompanyMembersBulkAction) => {
          const disabled =
            // cannot delete or remove yourself as admin
            ((action === 'Delete user' || action === 'Remove admin') && deleteOrRemoveDisabled) ||
            // cannot bulk remove admin when some selected are an admin
            (action === 'Remove admin' && !selectedRows.some((u) => u.isAdmin)) ||
            // cannot bulk assign admins when some selected are already admins
            (action === 'Assign admin' && selectedRows.some((u) => u.isAdmin)) ||
            // cannot assign roles if no eE or eM license purchased
            (action === 'Assign role' && !hasElectrical && !hasMechanical) ||
            // cannot assign a role to a user who is an admin
            (action === 'Assign role' && selectedRows.some((u) => u.isAdmin)) ||
            // cannot reset password on a user who has yet to accept invite
            (action === 'Reset password' && selectedRows.some((u) => u.status === 'Pending'));

          return { value: action, label: action, disabled };
        }),
    [deleteOrRemoveDisabled, hasElectrical, hasMechanical, selectedRows],
  );

  if (isNil(user)) return <Loader m="lg" />;

  return (
    <>
      <BasePageHeader
        title="Company Members"
        filterIsSet={filterIsSet || !!searchPhrase || showNoLicense || selectedProductPools.length > 0}
        onFilterClear={() => {
          setSearchPhrase('');
          searchHandler('');
          setShowNoLicense(false);
          setSelectedProductPools([]);
        }}
        gridRef={agGridProps.ref}
        noHistory
        topRightComponent={
          <>
            <Popover position="bottom-end">
              <Popover.Target>
                <Button variant="outline" color="gray" c="dark">
                  Downloads
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <Flex direction="column" gap="lg">
                  {user.company.companyDownloadPaths.map((d) => (
                    <CompanyDocument key={d.document} user={user} downloadPath={d} />
                  ))}
                  {user.company.companyDownloadPaths.length === 0 && (
                    <Text fz="sm" c="dimmed">
                      No downloads found.
                    </Text>
                  )}
                </Flex>
              </Popover.Dropdown>
            </Popover>
            <Button variant="outline" color="gray" c="dark" onClick={() => setViewPermissions(true)}>
              View Permissions
            </Button>
            <Button variant="outline" color="gray" c="dark" onClick={() => setConfigureRoleNames(true)}>
              Rename User Roles
            </Button>
            <InviteUsersModal pageType="company" refresh={refreshGrid} companyId={user.companyId} />
            <Button
              pl="xs"
              pr="xs"
              variant="outline"
              color="gray"
              c="dark"
              onClick={() => agGridProps.ref.current?.api.exportDataAsCsv({ fileName: 'Company Members' })}
            >
              <EvolveIcon icon="Download" />
            </Button>
          </>
        }
        bottomLeftComponent={
          <WrappedSelect
            disabled={selectedRows.length === 0}
            value={selectedAction}
            placeholder={selectedRows.length > 0 ? `Action (${selectedRows.length})` : 'Action'}
            data={dropdownData}
            onChange={setSelectedAction}
          />
        }
        bottomRightComponent={
          <WrappedMultiSelect<'No Licenses' | ProductPoolId>
            value={showNoLicense ? ['No Licenses'] : selectedProductPools}
            placeholder="Filter by license..."
            data={[
              {
                group: '',
                items: [
                  {
                    label: 'No Licenses',
                    value: 'No Licenses' as const,
                  },
                ],
              },
              {
                group: 'Licenses',
                items: productPools.map((p) => ({
                  label: p.product.productName,
                  value: p.productPoolId,
                })),
              },
            ]}
            onChange={(selected) => {
              if (showNoLicense || !selected.includes('No Licenses')) {
                setShowNoLicense(false);
                setSelectedProductPools(selected.filter((s): s is ProductPoolId => s !== 'No Licenses'));
              } else if (selected.includes('No Licenses')) {
                setShowNoLicense(true);
                setSelectedProductPools([]);
              }
            }}
            comboboxProps={{
              styles: {
                option: { marginTop: 1, marginBottom: 1 },
              },
            }}
            clearable
            style={{ width: 250 }}
          />
        }
      />

      <div style={{ height: '100%' }}>
        <AgGridReact<CompanyMember>
          {...agGridProps}
          defaultColDef={defaultColDef}
          rowSelection={rowSelection}
          onSelectionChanged={({ api }) => {
            setSelectedRows(api.getSelectedRows());
          }}
          noRowsOverlayComponent={() => <NoRowsOverlay label="No members found." gridRef={agGridProps.ref} />}
        />
      </div>
      <AddLicensesModal
        productPools={productPools}
        loadingProductPools={loadingProductPools}
        userIds={usersToAction.map((r) => r.userId)}
        opened={selectedAction === 'Assign license'}
        onClose={() => setSelectedAction(null)}
        refresh={() => {
          usersToAction.forEach((r) => {
            refreshDetailGrid(r.userId, 'Licenses');
            expandDetailGrid(r.userId, 'Licenses');
          });
          clearSelection();
        }}
      />
      <AssignRolesModal
        userIds={isNotNil(singleUserAction) ? [singleUserAction.userId] : usersToAction.map((r) => r.userId)}
        opened={selectedAction === 'Assign role'}
        assignableRoles={assignableRoles ?? []}
        onClose={() => setSelectedAction(null)}
        hasElectrical={hasElectrical}
        hasMechanical={hasMechanical}
        refresh={() => {
          refreshGrid();
          clearSelection();
        }}
      />
      <ConfirmationModal
        opened={isNotNil(removeRole)}
        onClose={() => setRemoveRole(null)}
        loading={removingRole}
        onConfirm={() => {
          if (isNotNil(removeRole)) {
            void doRemoveRole({ userRoleAssignments: [{ userId: removeRole.userId, roleId: removeRole.roleId }] }).then(
              () => {
                setRemoveRole(null);
                notifications.show({
                  title: 'Success',
                  message: 'Removed role successfully',
                });
                refreshGrid();
              },
            );
          }
        }}
      >
        Remove role <b>{removeRole?.roleName}</b> from user {removeRole?.email}?
      </ConfirmationModal>
      <ConfirmationModal
        title="Confirm delete users"
        opened={selectedAction === 'Delete user'}
        onClose={() => setSelectedAction(null)}
        loading={deleting}
        confirmationText="Delete"
        buttonColor="red"
        onConfirm={() => {
          void deleteUsers(usersToAction.map((r) => r.userId)).then(() => {
            clearSelection();
            refreshGrid();
            setSelectedAction(null);
          });
        }}
      >
        Are you sure you want to delete{' '}
        {usersToAction.length === 1 ? usersToAction[0].email : `${usersToAction.length} users`}?
      </ConfirmationModal>
      <ConfirmationModal
        title={`Confirm ${selectedAction === 'Assign admin' ? 'assign' : 'remove'} admin${usersToAction.length === 1 ? '' : 's'}`}
        opened={selectedAction === 'Assign admin' || selectedAction === 'Remove admin'}
        onClose={() => setSelectedAction(null)}
        loading={addingOrRemoving}
        confirmationText={selectedAction === 'Assign admin' ? 'Assign' : 'Remove'}
        buttonColor={selectedAction === 'Assign admin' ? 'green' : 'red'}
        onConfirm={() => {
          usersToAction.forEach((u) => {
            void addOrRemoveAdmin(
              { isAdmin: selectedAction === 'Assign admin' },
              { url: `admin/user/${u.userId}` },
            ).then(() => {
              notifications.show({
                title: 'Success',
                message: `User ${u.email} successfully ${selectedAction === 'Assign admin' ? 'assigned' : 'removed'} as an admin`,
              });
              clearSelection();
              refreshGrid();
              setSelectedAction(null);
            });
          });
        }}
      >
        {selectedAction === 'Assign admin'
          ? `Make ${usersToAction.length === 1 ? usersToAction[0].email + ' an admin' : usersToAction.length + ' users admins'}?`
          : `Remove ${usersToAction.length === 1 ? usersToAction[0].email + ' as an admin' : usersToAction.length + ' users as admins'}?`}
      </ConfirmationModal>
      <ConfirmationModal
        title="Confirm removal of roles"
        opened={selectedAction === 'Remove role'}
        onClose={() => setSelectedAction(null)}
        onConfirm={() => {
          if (isNotNil(singleUserAction)) {
            void doRemoveRole({
              userRoleAssignments:
                isNotNil(singleUserAction.electricalRoleId) && isNotNil(singleUserAction.mechanicalRoleId)
                  ? [
                      { userId: singleUserAction.userId, roleId: singleUserAction.electricalRoleId },
                      { userId: singleUserAction.userId, roleId: singleUserAction.mechanicalRoleId },
                    ]
                  : isNotNil(singleUserAction.electricalRoleId)
                    ? [{ userId: singleUserAction.userId, roleId: singleUserAction.electricalRoleId }]
                    : [{ userId: singleUserAction.userId, roleId: singleUserAction.mechanicalRoleId }],
            }).then(() => {
              notifications.show({
                title: 'Successfully removed roles',
                message: `All roles successfully removed from ${singleUserAction.email}`,
              });
              refreshGrid();
              setSingleUserAction(null);
              setSelectedAction(null);
            });
          }
        }}
      >
        Remove all roles for {singleUserAction?.email}?
      </ConfirmationModal>
      {isNotNil(assignableRoles) && assignableRoles.length > 0 && (
        <RoleNamesModal
          roles={assignableRoles}
          opened={configureRoleNames}
          onClose={() => {
            setConfigureRoleNames(false);
            refreshGrid();
          }}
          hasElectrical={hasElectrical}
          hasMechanical={hasMechanical}
          refetch={fetchAssignableRoles}
        />
      )}
      <ViewPermissionsModal viewPermissions={viewPermissions} onClose={() => setViewPermissions(false)} />
    </>
  );
};
