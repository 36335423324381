// Copyright ©️ 2025 eVolve MEP, LLC

import { useCallback, useMemo, useState } from 'react';

import { Flex, NavLink, useMantineTheme } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import { useMaybeUser } from 'app/MaybeUserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { useEvolveNavigate } from 'components/Mantine/Navigation/useEvolveNavigate';
import { replaceSpaceToDash } from 'helpers/stringFunctions';
import { uuIDValidator } from 'helpers/uuIDValidator';
import { useSetupModule } from 'hooks/useSetupModule';
import { useUrlParams } from 'hooks/useUrlParams';

import type { NavOption } from '../navigation';

type Props = {
  item: NavOption;
};

export const NavItem = ({ item }: Props) => {
  const { user } = useMaybeUser();
  const theme = useMantineTheme();
  const { currentModule, module } = useSetupModule();
  const location = useLocation();
  const navigate = useEvolveNavigate('', true);
  const {
    params: { nav },
  } = useUrlParams();

  const routeIsActive = useCallback(
    (routePath: string) => {
      const pathNames = location.pathname.split('/').filter((x) => x);
      const pathNameWithoutUUID = pathNames.filter((x) => !uuIDValidator(x)).join('/');

      if (nav) {
        return nav === routePath;
      }
      return pathNameWithoutUUID === `${module?.toLowerCase()}${routePath}`;
    },
    [location.pathname, module, nav],
  );

  const [opened, setOpened] = useState(
    item.isMenu && item.options.some((subItem) => !subItem.isMenu && routeIsActive(subItem.route)),
  );

  const handleClick = () => {
    if (!item.isMenu) {
      navigate(`${currentModule()?.toLowerCase()}${item.route}`);
    }
  };

  const dataTestIdOfItem = useMemo(() => `nav-item-${replaceSpaceToDash(item.displayText)}`, [item]);

  const active = !item.isMenu && routeIsActive(item.route);

  if (!user?.isAdmin && (item.companyAdminOnly || item.shopAdminOnly || item.projectAdminOnly)) {
    if (item.companyAdminOnly) return null;

    const passesShopCheck = user?.isShopAdmin || !item.shopAdminOnly;
    const passesProjectCheck = user?.isProjectAdmin || !item.shopAdminOnly;

    if (!passesShopCheck && !passesProjectCheck) return null;
  }

  return (
    <Flex direction="column">
      <NavLink
        label={item.displayText}
        active={active}
        onClick={handleClick}
        opened={opened}
        onChange={setOpened}
        py="xs"
        leftSection={<EvolveIcon icon={item.icon} />}
        variant="filled"
        childrenOffset={0}
        color={theme.primaryColor}
        styles={{
          root: {
            borderRadius: 6,
            color: opened || active ? 'white' : '#9e9e9e',
          },
          children: {
            borderRadius: 6,
            backgroundColor: '#424242',
          },
        }}
        data-testid={dataTestIdOfItem}
      >
        {item.isMenu &&
          item.options.map((subItem) => <NavItem item={subItem} key={`${currentModule()}-${subItem.displayText}`} />)}
      </NavLink>
    </Flex>
  );
};
