// Copyright ©️ 2025 eVolve MEP, LLC

import React, { type ReactNode, useEffect, useMemo, useState } from 'react';

import { useMaybeUser } from 'app/MaybeUserContext';
import { PRODUCT_IDS } from 'constants/globalConstants';
import { isNotNil } from 'helpers/isNotNil';
import { useGeneralContext } from 'helpers/useGeneralContext';
import { EModuleType } from 'types/module';
import type { Entitlement, ProductId } from 'types/types-api';

type ModuleContextType = {
  entitlements: Entitlement[] | undefined;
  modules: EModuleType[] | undefined;
  loading: boolean;
};

const ModuleContext = React.createContext<ModuleContextType | undefined>(undefined);

const productToModuleMapping = {
  [PRODUCT_IDS.SHOP_LICENSE]: 'SHOP',
  [PRODUCT_IDS.DESIGN_LICENSE]: 'DESIGN',
  [PRODUCT_IDS.FIELD_LICENSE]: 'FIELD',
  [PRODUCT_IDS.MATERIALS_LICENSE]: 'MATERIALS',
  [PRODUCT_IDS.SYNC_LICENSE]: 'SYNC',
} as const satisfies Record<ProductId, EModuleType>;

export const ModuleProvider = ({ children }: { children: ReactNode }) => {
  const { user, loading: loadingUser } = useMaybeUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // If we start loading the user again, set `loading` to `true`
    // and don't set it back to false until the new modules are defined
    // as part of the later useEffect
    setLoading((l) => loadingUser || l);
  }, [loadingUser]);

  const [modules, setModules] = useState<EModuleType[]>([]);

  useEffect(() => {
    if (isNotNil(user)) {
      setModules(
        [
          ...(user.isAdmin ? [EModuleType.ADMIN] : []),
          ...(user.isEvolveCompanyMember ? [EModuleType.MANAGEMENT] : []),
          ...(user.entitlements?.map((e) => productToModuleMapping[e.productPool.productId]).filter(isNotNil) ?? []),
        ].sort(),
      );
      setLoading(false);
    }
  }, [user]);

  const value = useMemo<ModuleContextType>(
    () => ({
      entitlements: user?.entitlements,
      modules,
      loading,
    }),
    [loading, modules, user?.entitlements],
  );

  return <ModuleContext.Provider value={value}>{children}</ModuleContext.Provider>;
};

export const useModule = () => useGeneralContext<ModuleContextType | undefined>(ModuleContext, 'Module');
