// Copyright ©️ 2025 eVolve MEP, LLC

import { useState, useEffect } from 'react';

import { Button, Checkbox, Flex, PasswordInput, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { EULAModal } from 'app/EULAModal';
import { useMaybeUser } from 'app/MaybeUserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { extractErrorMessage } from 'helpers/extractError';
import { useQuery } from 'hooks/useQuery';
import type { UserNameIdSymbol } from 'types/types-api';

import { useConfirmUser } from '../auth/restCalls';
import { useSignIn } from '../auth/useSignIn';
import { AuthFormWrapper } from '../components/AuthFormWrapper';
import type { LoginErrorCode } from '../components/LoginErrorAlert';

export const Login = () => {
  const theme = useMantineTheme();
  const { confirm } = useConfirmUser();
  const { refreshUser } = useMaybeUser();
  const { signIn } = useSignIn();
  const [signingIn, setSigningIn] = useState(false);

  const [EulaUser, setEulaUser] = useState<UserNameIdSymbol>();
  const [errorCode, setErrorCode] = useState<LoginErrorCode | string>();
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (errorCode) setSigningIn(false);
  }, [errorCode]);

  const navigate = useNavigate();
  const {
    // From src/modules/Authentication/ResetPassword.tsx
    resetPassword: changePasswordSuccess,
    // From src/modules/Authentication/Join/Join.tsx
    inviteConfirm: confirmationSuccess,
    // src/modules/Authentication/AcceptInvitation.tsx
    joinConfirm: joinSuccess,
  } = (useLocation().state ?? {}) as {
    resetPassword?: boolean;
    inviteConfirm?: boolean;
    joinConfirm?: string;
  };

  const query = useQuery();
  const queryErrorCode = query.get('errorCode') as LoginErrorCode | null;
  const username = query.get('username');
  const code = query.get('code');
  const redirect = query.get('redirect');
  useEffect(() => setErrorCode((c) => queryErrorCode ?? c), [queryErrorCode]);

  const form = useForm<{
    email: string;
    password: string;
    keepEnabled: boolean;
  }>({
    initialValues: {
      email: '',
      password: '',
      keepEnabled: false,
    },
  });

  const [successMessage, setSuccessMessage] = useState<string>();

  useEffect(() => {
    if (joinSuccess) {
      setSuccessMessage(`You have joined ${joinSuccess}! Login to continue.`);
    }
  }, [joinSuccess]);
  useEffect(() => {
    if (isConfirmed) {
      setSuccessMessage('Your email address has been verified!');
    }
  }, [isConfirmed]);
  useEffect(() => {
    if (changePasswordSuccess) {
      setSuccessMessage('Success! Your new password has been set.');
    }
  }, [changePasswordSuccess]);
  useEffect(() => {
    if (confirmationSuccess) {
      setSuccessMessage('Success! You have successfully joined.');
    }
  }, [confirmationSuccess]);

  useEffect(() => {
    if (!isConfirmed && username && code) {
      confirm(username, 'company', { confirmationCode: code })
        .then((response) => {
          if (response === 'SUCCESS') {
            setIsConfirmed(true);
          }
        })
        .catch(() => setIsConfirmed(false));
    }
  }, [code, confirm, isConfirmed, username]);

  const handleSignIn = async (values: typeof form.values) => {
    setErrorCode(undefined);
    setSuccessMessage(undefined);
    setSigningIn(true);

    try {
      await signIn(values);
      const user = await refreshUser();
      if (!user) {
        setErrorCode('UnknownException');
        return;
      }
      if (!user.acceptedLatestEula) {
        setEulaUser(user.userName);
        return;
      }
      void navigate(redirect ?? '/');
    } catch (e) {
      setErrorCode(extractErrorMessage(e));
    }
  };

  return (
    <>
      <AuthFormWrapper
        title="Login"
        form={form}
        onSubmit={handleSignIn}
        errorCode={errorCode}
        onCloseError={() => setErrorCode(undefined)}
        successMessage={successMessage}
        onCloseSuccess={() => setSuccessMessage(undefined)}
      >
        <>
          <TextInput
            label="Email"
            name="email"
            data-cy="email-input"
            size="md"
            autoFocus
            type="email"
            disabled={signingIn}
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Password"
            data-cy="password-input"
            name="password"
            size="md"
            disabled={signingIn}
            {...form.getInputProps('password')}
          />
          <Flex my="lg" justify="space-between" align="center">
            <Checkbox
              color="dark"
              size="md"
              label={
                <Flex align="center" gap={6}>
                  <Text>Keep me signed in</Text>
                  <AgGridStyleTooltip label="Leave this unchecked if this is a shared device." openDelay={0} withArrow>
                    <EvolveIcon icon="Info" size="sm" color={signingIn ? 'gray.4' : 'gray.7'} />
                  </AgGridStyleTooltip>
                </Flex>
              }
              disabled={signingIn}
              {...form.getInputProps('keepEnabled')}
            />
            <Link
              className="link"
              data-cy="forgot-password"
              to="/auth/forgot-password"
              state={{ email: form.values.email }}
            >
              <Text c={signingIn ? 'dimmed' : theme.primaryColor}>Forgot Password?</Text>
            </Link>
          </Flex>
          <Button
            type="submit"
            loading={signingIn}
            disabled={!form.values.email || !form.values.password}
            fullWidth
            color={`${theme.primaryColor}.9`}
          >
            Login
          </Button>
        </>
      </AuthFormWrapper>
      <EULAModal
        userName={EulaUser}
        onEulaAccepted={() => refreshUser().then(() => navigate('/'))}
        onClose={() => {
          setEulaUser(undefined);
          setSigningIn(false);
        }}
      />
    </>
  );
};
