// Copyright ©️ 2025 eVolve MEP, LLC

import { useEffect, useState } from 'react';

import { Anchor, AppShell, Box, Flex, Loader, Stack, Text, Title } from '@mantine/core';
import { Navigate } from 'react-router-dom';

import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { User } from 'types/types-api';

import { FullPageError } from './FullPageError';
import { HeaderBar } from './Layout/HeaderBar/HeaderBar';
import { ResetPasswordModal } from './Layout/HeaderBar/ProfileDropdown/ResetPasswordModal/ResetPasswordModal';
import { ComponentPaneLayout } from './Layout/ReactGridLayout/ComponentPaneLayout';
import { useModule } from './Modules/ModuleContext';
import { useUser } from './UserContext';
import ResetPasswordBackground from '../assets/images/backgrounds/reset-password-bg.png';

export const ResetPasswordLandingPage = ({ noLicense }: { noLicense?: boolean }) => {
  const { user } = useUser();
  const [passwordReset, setPasswordReset] = useState(false);
  const [companyAdmins, setCompanyAdmins] = useState<User[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const { modules } = useModule();

  const { fetchPage, loading: loadingAdmins } = useWrappedPaginatedGet<User>('admin/user', {
    lazy: true,
    defaultConfig: {
      params: {
        isAdmin: true,
        companyId: user.companyId,
      },
    },
  });

  useEffect(() => {
    void getAllDataFromFetcher(fetchPage).then(setCompanyAdmins);
  }, [fetchPage]);

  if (isNotNil(modules) && modules.length > 0 && noLicense) return <Navigate to="/" />;

  if (passwordReset) return <Navigate to="/auth/login" />;

  if (noLicense) {
    return (
      <FullPageError backgroundSrc={ResetPasswordBackground}>
        <HeaderBar noLicenses />
        <Title>{`You don't have a license.`}</Title>
        <Text ta="center">
          Hi there, it seems you do not have a license. Please request one from your company admin to gain access.
        </Text>
      </FullPageError>
    );
  }

  return (
    <>
      <HeaderBar noLicenses />
      <AppShell.Main>
        <ComponentPaneLayout>
          <Box p="lg">
            <BasePageHeader title="Helpful Links" />
            <Box mt="xl" mb="xl">
              <Flex>
                <Text mr="sm" c="gray.6" fw="bold">
                  Username:
                </Text>
                <Text c="gray.6">{user.userEmail}</Text>
              </Flex>
              <Flex>
                <Text mr="sm" c="gray.6" fw="bold">
                  Company Admin{companyAdmins.length > 0 && 's'}:
                </Text>
                {loadingAdmins && <Loader />}
                <Text c="gray.6">{companyAdmins.map((u) => u.userEmail).join(', ')}</Text>
              </Flex>
            </Box>
            <Flex>
              <Stack mt="xl" gap="xl" mr={200}>
                <Flex direction="column" gap="sm">
                  <Text fw="bold">Learning</Text>
                  <Anchor href="https://learning.evolvemep.com/account/signin">EVOLVE University Login</Anchor>
                  <Anchor href="https://info.evolvemep.com/weekly-office-hours">Office Hours</Anchor>
                </Flex>
                <Flex direction="column" gap="sm">
                  <Text fw="bold">Licensing</Text>
                  <Anchor href="https://evolvemep.com/downloads">Downloads</Anchor>
                  <Anchor onClick={() => setOpenModal(true)}>Reset Password</Anchor>
                </Flex>
              </Stack>
              <Flex direction="column" gap="sm" mt="xl">
                <Text fw="bold">Contact Support</Text>
                <Anchor href="https://evolvemep.com/contact-support">Contact</Anchor>
              </Flex>
            </Flex>
            <ResetPasswordModal
              opened={openModal}
              onClose={() => setOpenModal(false)}
              onComplete={() => setPasswordReset(true)}
            />
          </Box>
        </ComponentPaneLayout>
      </AppShell.Main>
    </>
  );
};
