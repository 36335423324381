// Copyright ©️ 2025 eVolve MEP, LLC

import type { ReactNode } from 'react';

import { Flex } from '@mantine/core';
import type { ColDef } from 'ag-grid-enterprise';
import type { LinkProps } from 'react-router-dom';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import type { PageTitle } from 'components/Mantine/Navigation/types';
import { TextWithRef } from 'components/Mantine/TextWithRef';
import { sortByStatusKey, taskStatusTypeNames } from 'constants/badgeMappingStatus';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { filterStatusConverter } from 'helpers/ag-grid/convertAgGridRequest';
import { formatDate } from 'helpers/dateOnly';
import type { StrongOmit } from 'helpers/helper-types';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';
import { TaskPlayPauseIcon } from 'modules/Shop/WorkCells/WorkCellsPage/TaskPlayPauseIcon';

import { getDaysRemainingWorkCellTask } from './common';
import { EditWorkCellTaskMenu } from './EditWorkCellTaskMenu';
import type { TaskStatusType, WorkCellTask } from './types';

export const WorkCellTaskLink = ({
  task,
  children,
  linkProps,
  hideTooltip,
  from = 'Work Cell Queue',
}: {
  task: Pick<WorkCellTask, 'isEnabled' | 'taskId'>;
  children: ReactNode;
  from?: PageTitle;
  linkProps?: Partial<StrongOmit<LinkProps, 'state'>>;
  hideTooltip?: boolean;
}) =>
  task.isEnabled ? (
    <EvolveLink to={`/shop/task-viewer/${task.taskId}`} from={from} {...linkProps}>
      {children}
    </EvolveLink>
  ) : (
    <AgGridStyleTooltip
      hidden={hideTooltip}
      label="This task has an incomplete predecessor."
      withArrow
      openDelay={150}
      position="top-start"
    >
      <TextWithRef span>{children}</TextWithRef>
    </AgGridStyleTooltip>
  );

export const getWorkCellTaskColDef = (taskStatusTypes: TaskStatusType[], showOpen: boolean): ColDef<WorkCellTask>[] => [
  {
    field: 'taskName',
    headerName: 'Task',
    cellRenderer: CellRenderer<WorkCellTask>(({ value, data }) => (
      <WorkCellTaskLink task={data}>
        <Flex align="center" gap="xs">
          <EvolveIcon icon={data.isEnabled ? 'WorkCellTask' : 'Blocked'} light />
          {value}
        </Flex>
      </WorkCellTaskLink>
    )),
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'workRequestItemName',
    headerName: 'Item',
    minWidth: 250,
    flex: 1,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'workOrderName',
    headerName: 'Work Order Id',
    tooltipValueGetter: ({ data }) => `${data?.workOrderName}: ${data?.workRequestName}`,
    cellRenderer: CellRenderer<WorkCellTask>(({ value, data }) => (
      <EvolveLink to={`/shop/work-orders/${data.workOrderId}`} from="Work Cell Queue">
        <Flex align="center" gap="xs">
          <EvolveIcon icon="WorkOrder" />
          {value}
        </Flex>
      </EvolveLink>
    )),
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'projectName',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    headerName: 'Status',
    width: 150,
    field: 'taskStatusTypeId',
    sortable: false,
    cellRenderer: CellRenderer<WorkCellTask>(({ data }) => (
      <Flex gap={6} align="center">
        <CellStatusBadge status={data.taskStatusTypeName} daysRemaining={getDaysRemainingWorkCellTask(data)} />
        <TaskPlayPauseIcon taskStatusTypeName={data.taskStatusTypeName} />
      </Flex>
    )),
    filter: showOpen ? 'customValueSetFilter' : false,
    filterParams: filterStatusConverter(
      taskStatusTypes.sort(sortByStatusKey(taskStatusTypeNames, 'taskStatusTypeName')),
      'taskStatusTypeId',
      'taskStatusTypeName',
    ),
  },
  {
    field: 'needBy',
    valueFormatter: (p) => formatDate(p.value, 'MM/dd/yyyy'),
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: ['inRange'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    ...lockedColDef('right'),
    width: 50,
    cellRenderer: CellRenderer<WorkCellTask>(
      ({ data, api }) => (
        <EditWorkCellTaskMenu workCellTask={data} refresh={() => api.refreshServerSide({ purge: true })} />
      ),
      { show: showOpen },
    ),
  },
];
