// Copyright ©️ 2025 eVolve MEP, LLC
import { useEffect } from 'react';

import { getThemeColor, Text, Timeline, useMantineTheme } from '@mantine/core';
import { parseISO } from 'date-fns';

import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { BoxWithRef } from 'components/Mantine/BoxWithRef';
import type { WorkRequestStatusName } from 'constants/badgeMappingStatus';
import { formatDate, type ISOTimestamp } from 'helpers/dateOnly';
import { isNotNil } from 'helpers/isNotNil';
import { TriggerOnVisible } from 'hooks/useOnScreen';
import { useUsersInfo } from 'hooks/useUsersInfo';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type {
  WorkRequestId,
  WorkRequestStatusTypeId,
} from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import type { UserId } from 'types/types-api';

type Props = {
  workRequestId: WorkRequestId;
};

type WorkRequestStatusHistoryItem = {
  workRequestStatusTypeId: WorkRequestStatusTypeId;
  workRequestStatus: WorkRequestStatusName;
  workRequestStatusDescription: string | null;
  createdOn: ISOTimestamp;
  createdBy: UserId | null;
};

export const WorkRequestStatusHistoryList = ({ workRequestId }: Props) => {
  const theme = useMantineTheme();
  const { getUsersData, cachedUserData } = useUsersInfo();
  const { data, fetchNextPage, loading } = useWrappedPaginatedGet<WorkRequestStatusHistoryItem>(
    `shop/workRequest/${workRequestId}/statusHistory`,
    {
      lazy: true,
      perPage: 20,
    },
  );

  useEffect(() => {
    void getUsersData(data.map((e) => e.createdBy).filter(isNotNil));
  }, [data, getUsersData]);

  return (
    <>
      <Timeline
        bulletSize={40}
        radius="sm"
        lineWidth={1}
        styles={{
          itemBody: {
            paddingTop: 10,
            paddingBottom: 10,
          },
          itemBullet: {
            backgroundColor: 'white',
            borderColor: 'gray.7',
            borderWidth: 1,
          },
        }}
      >
        {data.map((item, i) => {
          const createdOn = parseISO(item.createdOn);
          return (
            <Timeline.Item
              mt={i > 0 ? 'xs' : undefined}
              key={item.createdOn}
              title={item.workRequestStatus}
              bullet={
                <AgGridStyleTooltip label={formatDate(createdOn, 'PPP p')} openDelay={0} withArrow>
                  <BoxWithRef
                    display="flex"
                    c={i === 0 ? theme.primaryColor : undefined}
                    style={{
                      userSelect: 'none',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text fz="sm" mt={2} mb={-6} tt="uppercase">
                      {formatDate(createdOn, 'MMM')}
                    </Text>
                    <Text fw={600}>{formatDate(createdOn, 'd')}</Text>
                  </BoxWithRef>
                </AgGridStyleTooltip>
              }
              bg={i === 0 ? `${theme.primaryColor}.0` : undefined}
              styles={{
                itemBullet: {
                  borderColor: i === 0 ? getThemeColor(theme.primaryColor, theme) : undefined,
                },
              }}
            >
              {isNotNil(item.workRequestStatusDescription) && (
                <Text fz="sm" lineClamp={3} title={item.workRequestStatusDescription}>
                  {item.workRequestStatusDescription}
                </Text>
              )}
              {isNotNil(item.createdBy) && (
                <Text fz="sm" c="dimmed">
                  {cachedUserData.get(item.createdBy)?.completeName}
                </Text>
              )}
            </Timeline.Item>
          );
        })}
      </Timeline>
      <TriggerOnVisible loading={loading} onVisible={fetchNextPage} />
    </>
  );
};
