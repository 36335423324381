// Copyright ©️ 2025 eVolve MEP, LLC

import { Modal, Stack, Text, Table, Button, Flex } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import type { RolePermission } from './types';

type Props = {
  viewPermissions: boolean;
  onClose: () => void;
};

const rowData = [
  { permission: 'Can assign licenses', admin: true, powerUser: false, advancedUser: false, modeler: false },
  { permission: 'Can modify project configs', admin: true, powerUser: true, advancedUser: false, modeler: false },
  { permission: 'Can modify user configs', admin: true, powerUser: true, advancedUser: false, modeler: false },
  {
    permission: 'Can modify their own user configs',
    admin: true,
    powerUser: true,
    advancedUser: true,
    modeler: false,
  },
  {
    permission: 'Receives company controlled user configs',
    admin: false,
    powerUser: false,
    advancedUser: false,
    modeler: true,
  },
] as const satisfies RolePermission[];

const PermissionIcon = ({ role }: { role: boolean }) =>
  role ? <EvolveIcon icon="CheckCircle" color="teal" /> : <EvolveIcon icon="Unavailable" color="gray" />;

export const ViewPermissionsModal = ({ viewPermissions, onClose }: Props) => (
  <Modal
    opened={viewPermissions}
    onClose={onClose}
    centered
    title="Role Permissions"
    styles={{ content: { overflow: 'visible' } }}
  >
    <Stack w={800}>
      <Text c="gray" size="xs" mb="lg">
        View the actions each role can and cannot perform in Revit, specifically for setting and receiving user and
        project configurations.
      </Text>
      <Table>
        <Table.Thead>
          <Table.Tr display="flex">
            <Table.Th flex={3}>Permissions</Table.Th>
            <Table.Th flex={1}>Admin</Table.Th>
            <Table.Th flex={1}>Power User</Table.Th>
            <Table.Th flex={1}>Advanced User</Table.Th>
            <Table.Th flex={1}>User Roles 1-5</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {rowData.map((r) => (
            <Table.Tr key={r.permission} display="flex">
              <Table.Td flex={3}>{r.permission}</Table.Td>
              <Table.Td flex={1}>
                <PermissionIcon role={r.admin} />
              </Table.Td>
              <Table.Td flex={1}>
                <PermissionIcon role={r.powerUser} />
              </Table.Td>
              <Table.Td flex={1}>
                <PermissionIcon role={r.advancedUser} />
              </Table.Td>
              <Table.Td flex={1}>
                <PermissionIcon role={r.modeler} />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      <Flex justify="flex-end">
        <Button onClick={onClose}>Close</Button>
      </Flex>
    </Stack>
  </Modal>
);
