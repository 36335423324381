// Copyright ©️ 2025 eVolve MEP, LLC

import { type ComponentProps, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Loader, LoadingOverlay } from '@mantine/core';

import type { SelectedFacility } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import type { StrongOmit } from 'helpers/helper-types';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { ShopConfiguration, ShopConfigurationId } from 'hooks/projectsAndFacilities/types';
import type { FacilityId } from 'hooks/projectsAndFacilities/useFacilities';
import { useShopConfigurations } from 'hooks/projectsAndFacilities/useShopConfigurations';
import type { EvolveURL } from 'hooks-api/useEvolveApi';
import { useWrappedDelete, useWrappedGet, useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { TaskTypeId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type {
  WorkCellId,
  WorkCell,
  WorkCellTaskType,
} from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import { useWorkCellsGrid } from './useWorkCellsGrid';
import { WorkCellSetupContext, type WorkCellSetupContextType } from './WorkCellSetupContext';

const ShopSetupWrapperValidated = ({
  selectedFacility,
  activeShopConfiguration,
  children,
}: {
  selectedFacility: SelectedFacility;
  activeShopConfiguration: ShopConfiguration;
  children: ReactNode;
}) => {
  const [tasksNeedRefreshing, setTasksNeedRefreshing] = useState(false);
  const { refresh: refreshShopConfigurations } = useShopConfigurations();
  const [draftShopConfiguration, setDraftShopConfiguration] = useState<ShopConfiguration | undefined>();

  const draftConfigUrl = useMemo<EvolveURL>(
    () => `shop/configuration/${selectedFacility.id}/draft`,
    [selectedFacility.id],
  );
  const { data: draftShopConfigurationFromNetwork, loading: loadingDraftShopConfiguration } = useWrappedGet<
    ShopConfiguration | undefined
  >(draftConfigUrl);
  useEffect(() => {
    if (isNotNil(draftShopConfigurationFromNetwork)) {
      setDraftShopConfiguration(draftShopConfigurationFromNetwork);
    }
  }, [draftShopConfigurationFromNetwork]);

  /** `serverSideGridProps` specifically for the "Draft" table */
  const serverSideGridProps = useWorkCellsGrid(
    selectedFacility,
    'draft',
    draftShopConfiguration,
    isNotNil(draftShopConfiguration),
  );

  const { apiCall: createWorkCellApiCall, loading: creating } = useWrappedPost<
    WorkCell,
    { workCellName: string; shopConfigurationId: ShopConfigurationId; facilityId: FacilityId }
  >('shop/workCell');
  const { apiCall: updateWorkCellApiCall, loading: updating } = useWrappedPatch<WorkCell, { workCellName: string }>(
    'shop/workCell/TODO',
  );
  const { apiCall: deleteWorkCellApiCall, loading: deleting } = useWrappedDelete<unknown>('shop/workCell/TODO');
  const { apiCall: createWorkCellTaskTypeApiCall, loading: creatingTaskType } = useWrappedPost<
    WorkCellTaskType,
    {
      workCellIds: [WorkCellId];
      taskTypeId: TaskTypeId;
    }
  >('shop/workCellTaskType');
  const { apiCall: deleteWorkCellTaskTypeApiCall, loading: deletingTaskType } =
    useWrappedDelete<unknown>('shop/workCellTaskType/TODO');

  const createWorkCell = useCallback<WorkCellSetupContextType['createWorkCell']>(
    async (newWorkCell, shopConfigurationId) =>
      createWorkCellApiCall({ ...newWorkCell, shopConfigurationId, facilityId: selectedFacility.id }),
    [createWorkCellApiCall, selectedFacility.id],
  );
  const updateWorkCell = useCallback<WorkCellSetupContextType['updateWorkCell']>(
    async ({ workCellId, ...workCell }) => updateWorkCellApiCall(workCell, { url: `shop/workCell/${workCellId}` }),
    [updateWorkCellApiCall],
  );
  const deleteWorkCell = useCallback<WorkCellSetupContextType['deleteWorkCell']>(
    async (workCellId) => {
      await deleteWorkCellApiCall({ url: `shop/workCell/${workCellId}` }).then(() => setTasksNeedRefreshing(true));
    },
    [deleteWorkCellApiCall],
  );
  const mapWorkCellTaskType = useCallback<WorkCellSetupContextType['mapWorkCellTaskType']>(
    async (workCellId, taskTypeId) =>
      createWorkCellTaskTypeApiCall({ workCellIds: [workCellId], taskTypeId }).then(async (res) => {
        setTasksNeedRefreshing(true);
        return res;
      }),
    [createWorkCellTaskTypeApiCall],
  );
  const unmapWorkCellTaskType = useCallback<WorkCellSetupContextType['unmapWorkCellTaskType']>(
    async (workCellTaskTypeId) => {
      await deleteWorkCellTaskTypeApiCall({ url: `shop/workCellTaskType/${workCellTaskTypeId}` }).then(() =>
        setTasksNeedRefreshing(true),
      );
    },
    [deleteWorkCellTaskTypeApiCall],
  );

  const { apiCall: createDraftShopConfigApiCall, loading: creatingDraft } = useWrappedPost<ShopConfiguration, any>(
    draftConfigUrl,
  );
  const { apiCall: deleteDraftShopConfigApiCall, loading: deletingDraft } = useWrappedDelete(draftConfigUrl);
  const { apiCall: applyDraftShopConfigApiCall, loading: applyingDraft } = useWrappedPost<ShopConfiguration, any>(
    `${draftConfigUrl}/set-current`,
  );

  const createDraftShopConfiguration = useCallback(async () => {
    await createDraftShopConfigApiCall({}).then(setDraftShopConfiguration);
  }, [createDraftShopConfigApiCall]);
  const deleteDraftShopConfiguration = useCallback(async () => {
    await deleteDraftShopConfigApiCall().then(() => setDraftShopConfiguration(undefined));
  }, [deleteDraftShopConfigApiCall]);
  const applyDraftShopConfiguration = useCallback(async () => {
    await applyDraftShopConfigApiCall({}).then(() => {
      setDraftShopConfiguration(undefined);
      void refreshShopConfigurations();
    });
  }, [applyDraftShopConfigApiCall, refreshShopConfigurations]);
  const bigMutationRunning = creatingDraft || deletingDraft || applyingDraft;
  const mutationRunning =
    creating || updating || deleting || creatingTaskType || deletingTaskType || bigMutationRunning;

  return (
    <WorkCellSetupContext.Provider
      value={useMemo(
        () => ({
          activeShopConfiguration,
          draftShopConfiguration,
          loadingDraftShopConfiguration,
          tasksNeedRefreshing,
          setTasksNeedRefreshing,
          createDraftShopConfiguration,
          deleteDraftShopConfiguration,
          applyDraftShopConfiguration,
          selectedFacility,
          serverSideGridProps,
          createWorkCell,
          updateWorkCell,
          deleteWorkCell,
          mapWorkCellTaskType,
          unmapWorkCellTaskType,
          mutationRunning,
        }),
        [
          activeShopConfiguration,
          draftShopConfiguration,
          loadingDraftShopConfiguration,
          tasksNeedRefreshing,
          createDraftShopConfiguration,
          deleteDraftShopConfiguration,
          applyDraftShopConfiguration,
          selectedFacility,
          serverSideGridProps,
          createWorkCell,
          updateWorkCell,
          deleteWorkCell,
          mapWorkCellTaskType,
          unmapWorkCellTaskType,
          mutationRunning,
        ],
      )}
    >
      {children}
      <LoadingOverlay zIndex={1} visible={bigMutationRunning || loadingDraftShopConfiguration} />
    </WorkCellSetupContext.Provider>
  );
};

export const ShopSetupWrapper = (
  props: StrongOmit<ComponentProps<typeof ShopSetupWrapperValidated>, 'activeShopConfiguration'>,
) => {
  const { activeShopConfiguration, loading: loadingShopConfigurations } = useShopConfigurations();

  if (isNil(activeShopConfiguration) || loadingShopConfigurations) {
    return <Loader m="lg" />;
  }
  return <ShopSetupWrapperValidated activeShopConfiguration={activeShopConfiguration} {...props} />;
};
