// Copyright ©️ 2025 eVolve MEP, LLC

import { Anchor, Flex, Text, useMantineTheme } from '@mantine/core';
import type { ColDef } from 'ag-grid-enterprise';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { customSetFilterParams } from 'helpers/ag-grid/convertAgGridRequest';
import { CustomSelectComponent } from 'helpers/ag-grid/CustomSelectComponent';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { User, Entitlement } from 'types/types-api';

import { CompanyMemberMenu, type CompanyMembersBulkAction } from './CompanyMemberMenu';
import { RemoveEntitlementOrRole } from './RemoveEntitlementOrRole';
import type { AssignableRole, CompanyMember, RoleToRemove } from './types';

const fallback = '--';

export const getCompanyMembersColDef = ({
  hasDetailTable,
  actionSingleUser,
  filterOnStatus = true,
  includeSearch = false,
  roles,
  setRemoveRole,
  hasElectrical,
  hasMechanical,
}: {
  hasDetailTable: boolean;
  filterOnStatus?: boolean;
  includeSearch?: boolean;
  actionSingleUser?: (user: CompanyMember, selectedAction: CompanyMembersBulkAction) => void;
  roles?: AssignableRole[];
  setRemoveRole?: ({ userId, roleId, roleName, email }: RoleToRemove) => void;
  hasElectrical?: boolean;
  hasMechanical?: boolean;
}): ColDef<CompanyMember>[] =>
  [
    {
      ...lockedColDef(),
      width: 48,
      cellRenderer: 'agGroupCellRenderer',
      hide: !hasDetailTable,
    },
    {
      ...lockedColDef(),
      headerName: 'Admin',
      field: 'isAdmin',
      width: 100,
      cellRenderer: CellRenderer<User>(({ data }) => {
        const theme = useMantineTheme();
        if (data.isAdmin) return <EvolveIcon icon="AdminIcon" size="md" color={theme.primaryColor} />;
        return null;
      }),
      filter: CustomSelectComponent({
        label: 'Members',
        placeholder: 'All members',
        defaultValue: null,
        data: [
          {
            label: 'Admins',
            value: 'true',
          },
          {
            label: 'Non-admins',
            value: 'false',
          },
        ],
        filterKey: 'isAdmin',
      }),
      filterParams: {
        buttons: ['reset'],
      },
    },
    {
      field: 'name',
      width: 200,
      filter: includeSearch ? 'agTextColumnFilter' : undefined,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
        buttons: ['reset'],
      },
      // if name is an empty string (falsy), use fallback value
      valueFormatter: ({ value }) => value || fallback,
    },
    {
      headerName: 'Email',
      field: 'email',
      valueFormatter: ({ value }) => value ?? fallback,
      flex: 1,
      minWidth: 250,
      filter: includeSearch ? 'agTextColumnFilter' : undefined,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
        buttons: ['reset'],
      },
    },
    {
      headerName: 'Electrical',
      field: 'electricalRoleId',
      hide: !hasElectrical,
      filter: 'customValueSetFilter',
      filterParams: roles
        ? customSetFilterParams(
            roles.filter((r) => r.scope === 'EE'),
            'roleId',
            'displayName',
          )
        : null,
      valueGetter: ({ data }) => (data?.isAdmin ? 'Admin' : (data?.electricalRole ?? '')),
      cellRenderer: CellRenderer<CompanyMember>(({ value, data }) => (
        <Flex className="hover-title" align="center" gap="xs">
          <Text>{value}</Text>
          {isNotNil(setRemoveRole) && !data.isAdmin && (
            <Anchor
              className="hover-title--on"
              onClick={() =>
                setRemoveRole({
                  roleId: data.electricalRoleId,
                  userId: data.userId,
                  roleName: data.electricalRole,
                  email: data.email,
                })
              }
            >
              <EvolveIcon icon="Delete" color="gray" />
            </Anchor>
          )}
        </Flex>
      )),
    },
    {
      headerName: 'Mechanical',
      field: 'mechanicalRoleId',
      hide: !hasMechanical,
      filter: 'customValueSetFilter',
      filterParams: roles
        ? customSetFilterParams(
            roles.filter((r) => r.scope === 'EM'),
            'roleId',
            'displayName',
          )
        : null,
      valueGetter: ({ data }) => (data?.isAdmin ? 'Admin' : (data?.mechanicalRole ?? '')),
      cellRenderer: CellRenderer<CompanyMember>(({ value, data }) => (
        <Flex className="hover-title" align="center" gap="xs">
          <Text>{value}</Text>
          {isNotNil(setRemoveRole) && !data.isAdmin && (
            <Anchor
              className="hover-title--on"
              onClick={() =>
                setRemoveRole({
                  roleId: data.mechanicalRoleId,
                  userId: data.userId,
                  roleName: data.mechanicalRole,
                  email: data.email,
                })
              }
            >
              <EvolveIcon icon="Delete" color="gray" />
            </Anchor>
          )}
        </Flex>
      )),
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 150,
      cellStyle: ({ data }) => ({
        color: data?.status === 'Deleted' ? 'red' : data?.status === 'Active' ? 'teal' : 'orange',
      }),
      filter: filterOnStatus
        ? CustomSelectComponent({
            label: 'Member status',
            defaultValue: 'Active, Pending',
            data: [
              {
                label: 'Active',
                value: 'Active, Pending',
              },
              {
                label: 'Deleted',
                value: 'Deleted',
              },
              {
                label: 'All',
                value: 'Active, Pending, Deleted',
              },
            ],
            filterKey: 'status',
          })
        : undefined,
    },
    {
      ...lockedColDef('right'),
      width: 56,
      cellRenderer: CellRenderer<CompanyMember>(
        ({ data }) =>
          isNotNil(actionSingleUser) ? (
            <CompanyMemberMenu
              actionSingleUser={actionSingleUser}
              user={data}
              hasElectrical={hasElectrical ?? false}
              hasMechanical={hasMechanical ?? false}
            />
          ) : null,
        { show: ({ data }) => data.status !== 'Deleted' },
      ),
      hide: isNil(actionSingleUser),
    },
  ] as const;

export const getEntitlementsColDef = (): ColDef<Entitlement>[] =>
  [
    {
      ...lockedColDef(),
      width: 60,
      cellRenderer: CellRenderer<Entitlement>(({ data, api }) => (
        <RemoveEntitlementOrRole entitlement={data} refresh={() => api.refreshServerSide({ purge: true })} />
      )),
    },
    {
      field: 'productPool.label',
      flex: 1,
    },
  ] as const;
