// Copyright ©️ 2025 eVolve MEP, LLC

import { useEffect, useMemo } from 'react';

import { useMaybeUser } from 'app/MaybeUserContext';
import type { LOCAL_STORAGE_KEY, LOCAL_STORAGE_TYPE } from 'constants/globalConstants';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { strongIsEqual as isEqual } from 'helpers/strongEntries';
import { useLocalStorage } from 'hooks/useLocalStorage';

import type { EvolveURL } from './useEvolveApi';
import { type GetOpts, useWrappedGet } from './useWrappedApiCall';

/**
 * Returns a response cached in local storage,
 * and will update the local storage if the data retrieved from the network
 * is different than the cache.
 */
export const useWrappedGetWithLocalStorage = <
  K extends LOCAL_STORAGE_KEY,
  V extends LOCAL_STORAGE_TYPE[K] = LOCAL_STORAGE_TYPE[K],
  DataType = any,
>(
  localStorageKey: K,
  initialValue: V,
  url: EvolveURL,
  opts?: GetOpts<DataType>,
  waitForLogin = false,
) => {
  const { user } = useMaybeUser();
  const [stored, setStored] = useLocalStorage(localStorageKey, initialValue);
  const { data: fromNetwork, apiCall, ...rest } = useWrappedGet<V>(url, { lazy: waitForLogin, ...opts });

  useEffect(() => {
    if (waitForLogin && isNotNil(user)) void apiCall();
  }, [apiCall, user, waitForLogin]);

  useEffect(() => {
    if (!isNil(fromNetwork) && !isEqual(stored, fromNetwork)) {
      setStored(fromNetwork);
    }
  }, [fromNetwork, setStored, stored]);
  return useMemo(
    () => ({
      ...rest,
      apiCall,
      data: stored,
    }),
    [rest, apiCall, stored],
  );
};
