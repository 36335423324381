// Copyright ©️ 2025 eVolve MEP, LLC

import React, { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { notifications } from '@mantine/notifications';

import { useUser } from 'app/UserContext';
import { extractErrorMessage } from 'helpers/extractError';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import type { StrongOmit } from 'helpers/helper-types';
import { isNotNil } from 'helpers/isNotNil';
import { useGeneralContext } from 'helpers/useGeneralContext';
import { useWrappedDelete, useWrappedPaginatedGet, useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { Address, CompanyId } from 'types/types-api';

import type { Department, DepartmentId, DepartmentTypeId } from './types';
import { useSelectedProjectFacility } from './useSelectedProjectFacility';

declare const facilityIdSymbol: unique symbol;
export type FacilityId = string & { [facilityIdSymbol]: never };

export type Facility = {
  facilityId: FacilityId;
  facilityName: string;
  companyId: CompanyId;
  address: StrongOmit<Address, 'addressId'>;
  departments: Department[] | null;
  facilityImageId: string | null;
  deliveryLeadTime: number | null;
};

export type FacilityUpdate = StrongOmit<Facility, 'facilityId' | 'departments' | 'facilityImageId'> & {
  departments?: {
    departmentId?: DepartmentId;
    departmentTypeId: DepartmentTypeId;
    departmentName: string;
    userId?: string;
    isAdmin?: boolean;
  }[];
};

type FacilitiesContextType = {
  facilities: Facility[];
  loading: boolean;
  mutationRunning: boolean;
  errors: any;
  createFacility: (data: FacilityUpdate) => Promise<Facility>;
  updateFacility: (facilityId: FacilityId, data: FacilityUpdate) => Promise<Facility>;
  deleteFacility: (facilityId: FacilityId) => Promise<void>;
  deleteDepartment: (departmentId: DepartmentId) => Promise<void>;
};

const FacilitiesContext = React.createContext<FacilitiesContextType | undefined>(undefined);

export const FacilitiesProvider = ({ children }: { children: ReactNode }) => {
  const { selectedItem, clearSelectedItem } = useSelectedProjectFacility();
  const { user } = useUser();
  const [facilities, setFacilities] = useState<Facility[] | null>(null);
  const { fetchPage, loading, errors } = useWrappedPaginatedGet<Facility>('admin/facility', { lazy: true });

  useEffect(() => {
    if (
      isNotNil(facilities) &&
      selectedItem?.type === 'FACILITY' &&
      !facilities.some((f) => f.facilityId === selectedItem.id)
    ) {
      clearSelectedItem();
    }
  }, [clearSelectedItem, facilities, selectedItem?.id, selectedItem?.type]);

  const refetch = useCallback(async () => {
    await getAllDataFromFetcher(fetchPage, {
      params: {
        companyId: user.companyId,
      },
    }).then(setFacilities);
  }, [fetchPage, user.companyId]);

  useEffect(() => {
    void refetch();
  }, [refetch]);

  const { apiCall: createCall, loading: createLoading } = useWrappedPost<Facility, FacilityUpdate>('admin/facility');
  const { apiCall: updateCall, loading: updateLoading } = useWrappedPatch<Facility, FacilityUpdate>('admin/facility');
  const { apiCall: deleteCall, loading: deleteLoading } = useWrappedDelete<unknown>('admin/facility');

  const createFacility = useCallback<FacilitiesContextType['createFacility']>(
    async (data) => {
      const res = await createCall(data);
      notifications.show({
        title: 'Successfully created',
        message: `Facility ${res.facilityName} created.`,
        color: 'green',
      });
      await refetch();
      return res;
    },
    [createCall, refetch],
  );

  const updateFacility = useCallback<FacilitiesContextType['updateFacility']>(
    async (facilityId, data) => {
      const res = await updateCall(data, {
        url: `admin/facility/${facilityId}`,
      });
      notifications.show({
        title: 'Successfully updated',
        message: `Facility ${res.facilityName} updated.`,
        color: 'green',
      });
      await refetch();
      return res;
    },
    [updateCall, refetch],
  );

  const deleteFacility = useCallback<FacilitiesContextType['deleteFacility']>(
    async (facilityId) => {
      await deleteCall({
        url: `admin/facility/${facilityId}`,
      });
      notifications.show({
        title: 'Successfully deleted',
        message: 'Facility deleted.',
        color: 'green',
      });
      await refetch();
    },
    [deleteCall, refetch],
  );

  const { apiCall: deleteDepartmentCall, loading: deleteDepartmentLoading } = useWrappedDelete<unknown>(
    'admin/department',
    { dontAlertOnError: true },
  );
  const deleteDepartment = useCallback<FacilitiesContextType['deleteDepartment']>(
    async (departmentId) => {
      await deleteDepartmentCall({
        url: `admin/department/${departmentId}`,
      }).catch((err) => {
        let extractedError = extractErrorMessage(err);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err.response?.data?.errors) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          extractedError = Object.keys(err.response.data.errors).join(' ');
        }
        notifications.show({
          title: 'Something went wrong',
          message: `${extractedError}`,
          color: 'red',
        });
        throw err;
      });
      notifications.show({
        title: 'Successfully deleted',
        message: 'Department deleted.',
        color: 'green',
      });
      await refetch();
    },
    [deleteDepartmentCall, refetch],
  );

  const value = useMemo(
    () => ({
      facilities: facilities ?? [],
      loading,
      mutationRunning: createLoading || updateLoading || deleteLoading || deleteDepartmentLoading,
      errors,
      createFacility,
      updateFacility,
      deleteFacility,
      deleteDepartment,
    }),
    [
      facilities,
      loading,
      createLoading,
      updateLoading,
      deleteLoading,
      deleteDepartmentLoading,
      errors,
      createFacility,
      updateFacility,
      deleteFacility,
      deleteDepartment,
    ],
  );
  return <FacilitiesContext.Provider value={value}>{children}</FacilitiesContext.Provider>;
};

export const useFacilities = () => useGeneralContext(FacilitiesContext, 'Facilities');
