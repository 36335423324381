// Copyright ©️ 2025 eVolve MEP, LLC

import { Table, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { UserId } from 'types/types-api';

import type { AssignableRole, RoleEntry } from './types';

type Props = {
  userIds: UserId[];
  opened: boolean;
  assignableRoles: AssignableRole[];
  hasMechanical: boolean;
  hasElectrical: boolean;
  onClose: () => void;
  refresh: () => void;
};

type FormValues = {
  mechanicalRole: RoleEntry['roleId'];
  electricalRole: RoleEntry['roleId'];
};

export const AssignRolesModal = ({
  userIds,
  opened,
  onClose,
  hasElectrical,
  hasMechanical,
  refresh,
  assignableRoles,
}: Props) => {
  const { apiCall: assignRoles } = useWrappedPost<unknown, { userRoleAssignments: RoleEntry[] }>('admin/Role/assign');

  const form = useForm<FormValues>({
    initialValues: {
      mechanicalRole: null,
      electricalRole: null,
    },
  });

  const onSubmit = () => {
    void assignRoles({
      userRoleAssignments: userIds.flatMap((u) => {
        const eAssignment = [{ userId: u, roleId: form.values.electricalRole }];
        const mAssignment = [{ userId: u, roleId: form.values.mechanicalRole }];
        if (form.isDirty('electricalRole') && form.isDirty('mechanicalRole')) return [...eAssignment, ...mAssignment];
        if (form.isDirty('electricalRole')) return eAssignment;
        if (form.isDirty('mechanicalRole')) return mAssignment;
        return [];
      }),
    }).then(() => {
      notifications.show({
        title: 'Successfully assigned',
        message: `Assigned role${form.isDirty('electricalRole') && form.isDirty('mechanicalRole') ? 's' : ''} to ${userIds.length} member${userIds.length === 1 ? '' : 's'}`,
      });
      refresh();
      form.reset();
      onClose();
    });
  };
  return (
    <ConfirmationModal
      title="Assign Roles"
      opened={opened}
      onClose={() => {
        form.reset();
        onClose();
      }}
      onConfirm={onSubmit}
      disabled={!form.isDirty()}
    >
      <Text size="sm" c="dimmed" mb="sm">
        {userIds.length} user{userIds.length === 1 ? '' : 's'} selected
      </Text>
      <form>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Product</Table.Th>
              <Table.Th>New Role</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {hasElectrical && (
              <Table.Tr>
                <Table.Td>Electrical</Table.Td>
                <Table.Td>
                  <WrappedSelect
                    {...form.getInputProps('electricalRole')}
                    placeholder="No change"
                    clearable
                    onClear={() => {
                      form.setFieldValue('electricalRole', null);
                      form.setDirty({ electricalRole: false });
                    }}
                    data={assignableRoles
                      .filter((r) => r.scope === 'EE')
                      .map((r) => ({ label: r.displayName, value: r.roleId }))}
                  />
                </Table.Td>
              </Table.Tr>
            )}
            {hasMechanical && (
              <Table.Tr>
                <Table.Td>Mechanical</Table.Td>
                <Table.Td>
                  <WrappedSelect
                    {...form.getInputProps('mechanicalRole')}
                    placeholder="No change"
                    clearable
                    onClear={() => {
                      form.setFieldValue('mechanicalRole', null);
                      form.setDirty({ mechanicalRole: false });
                    }}
                    data={assignableRoles
                      .filter((r) => r.scope === 'EM')
                      .map((r) => ({ label: r.displayName, value: r.roleId }))}
                  />
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </form>
    </ConfirmationModal>
  );
};
