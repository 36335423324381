// Copyright ©️ 2025 eVolve MEP, LLC
import { useCallback, useEffect } from 'react';

import { Tabs } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { canEditWorkRequest, canEditWorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/common';

import { useWorkRequestOrderItems } from '../WorkRequestOrderItemsContext';
import { AddWriteInItem } from './AddItems/AddWriteInItem';
import { Catalog } from './AddItems/Catalog';
import { TasksView } from './AddTasks/TasksView';
import { WorkRequestOrderDetailList } from './WorkRequestOrderDetail/WorkRequestOrderDetailList';
import { WorkRequestStatusHistoryList } from './WorkRequestOrderDetail/WorkRequestStatusHistoryList';
import { WorkRequestOrderPartAdder } from './WorkRequestOrderPartAdder';

export type DetailTab = 'detail' | 'history' | 'catalog' | 'tasks';

export const WorkRequestOrderDetailsPane = () => {
  const props = useWorkRequestOrderItems();
  const { setSelectedTab, selectedTab } = props;
  const canEditItems =
    'workOrder' in props
      ? canEditWorkOrder(props.workOrder.workOrderStatusTypeName, 'editItems')
      : canEditWorkRequest(props.workRequest.workRequestStatusName, 'editItems');
  const canEditTasks =
    props.selectedItem.type === 'FACILITY' &&
    ('workOrder' in props
      ? canEditWorkOrder(props.workOrder.workOrderStatusTypeName, 'editTasks')
      : canEditWorkRequest(props.workRequest.workRequestStatusName, 'editTasks'));
  useEffect(() => {
    if (!canEditItems && !canEditTasks && selectedTab !== 'detail' && selectedTab !== 'history') {
      setSelectedTab('detail');
    }
  }, [canEditItems, canEditTasks, selectedTab, setSelectedTab]);
  const refresh = useCallback(() => props.serverSideGridProps.refreshGrid(), [props.serverSideGridProps]);
  return (
    <Tabs
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      value={selectedTab}
      onChange={(t) => setSelectedTab(t as DetailTab)}
      variant="outline"
      orientation="horizontal"
    >
      <Tabs.List mb="xs">
        <Tabs.Tab
          value="detail"
          leftSection={
            <EvolveIcon icon={'workOrder' in props ? 'WorkOrder' : 'WorkRequest'} light={selectedTab !== 'detail'} />
          }
        >
          Properties
        </Tabs.Tab>
        <Tabs.Tab
          value="catalog"
          disabled={!canEditItems}
          leftSection={<EvolveIcon icon="Assembly" light={selectedTab !== 'catalog'} />}
          style={{ cursor: !canEditItems ? 'initial' : undefined }}
        >
          Add Items
        </Tabs.Tab>
        <Tabs.Tab
          value="tasks"
          disabled={!canEditTasks}
          leftSection={<EvolveIcon icon="WorkCellTask" light={selectedTab !== 'tasks'} />}
          style={{ cursor: !canEditTasks ? 'initial' : undefined }}
        >
          Add Tasks
        </Tabs.Tab>
        {/* We do not (yet) track status history for Work Orders */}
        {'workRequest' in props && (
          <Tabs.Tab
            value="history"
            // When open as Submitted in a Facility, the WR automatically moves to Pending,
            // So wait for that status update to go through
            disabled={props.workRequest.workRequestStatusName === 'Submitted' && props.selectedItem.type === 'PROJECT'}
            leftSection={<EvolveIcon icon="History" light={selectedTab !== 'history'} />}
          >
            History
          </Tabs.Tab>
        )}
      </Tabs.List>
      <Tabs.Panel
        value="detail"
        px="xs"
        styles={{
          panel: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            overflowY: 'auto',
          },
        }}
      >
        <WorkRequestOrderDetailList {...props} isDrawer={false} />
      </Tabs.Panel>
      <Tabs.Panel value="catalog" styles={{ panel: { display: 'flex', flexDirection: 'column', flex: '1 1 auto' } }}>
        <Catalog
          rightSideComponent={<AddWriteInItem {...props} refresh={refresh} />}
          refresh={refresh}
          companyId={props.selectedItem.companyId}
        >
          {(listingProps) => <WorkRequestOrderPartAdder {...listingProps} {...props} />}
        </Catalog>
      </Tabs.Panel>
      <Tabs.Panel
        value="tasks"
        styles={{ panel: { display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' } }}
      >
        <TasksView
          {...props}
          refresh={(billOfProcessId) => {
            props.serverSideGridProps.refreshDetailGrid(billOfProcessId, 'Bill of Process');
            props.serverSideGridProps.expandDetailGrid(billOfProcessId, 'Bill of Process');
          }}
        />
      </Tabs.Panel>
      {'workRequest' in props && (
        <Tabs.Panel
          value="history"
          p="xs"
          styles={{
            panel: {
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto',
              overflowY: 'auto',
            },
          }}
        >
          <WorkRequestStatusHistoryList workRequestId={props.workRequest.workRequestId} />
        </Tabs.Panel>
      )}
    </Tabs>
  );
};
