// Copyright ©️ 2025 eVolve MEP, LLC

import { useMemo } from 'react';

import { Flex, Modal, Table, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { ModalButtons } from 'components/Mantine/ModalButtons';
import { isNotNil } from 'helpers/isNotNil';
import { strongKeys } from 'helpers/strongEntries';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

import type { AssignableRole, RoleNameFormEntries, NameableRole, RoleCustomDisplay } from './types';

type Props = {
  opened: boolean;
  onClose: () => void;
  roles: AssignableRole[];
  hasElectrical: boolean;
  hasMechanical: boolean;
  refetch: () => void;
};

const ModalContents = ({ onClose, roles, hasElectrical, hasMechanical, refetch }: Omit<Props, 'opened'>) => {
  const { apiCall: setDisplayNames, loading } = useWrappedPost<unknown, { customDisplayNames: RoleCustomDisplay[] }>(
    'admin/Role/setCustomDisplayNames',
  );

  const eEeMEditableRoles = useMemo(
    () => [...new Set(roles.filter((r) => r.isDisplayNameEditable).map((r) => r.defaultDisplayName))],
    [roles],
  );

  const form = useForm<RoleNameFormEntries>({
    mode: 'uncontrolled',
    initialValues: eEeMEditableRoles.reduce(
      (o, key) => ({
        ...o,
        [key]: {
          eERole: roles.find((r) => r.scope === 'EE' && r.defaultDisplayName === key),
          eMRole: roles.find((r) => r.scope === 'EM' && r.defaultDisplayName === key),
        },
      }),
      {},
    ),
  });

  const rowData: NameableRole[] = useMemo(
    () =>
      // sort ensures that user roles will always be displayed 1 - 5
      eEeMEditableRoles.sort().map((dn) => ({
        defaultDisplayName: dn,
        eERole: roles.find((r) => r.scope === 'EE' && r.defaultDisplayName === dn),
        eMRole: roles.find((r) => r.scope === 'EM' && r.defaultDisplayName === dn),
      })),
    [eEeMEditableRoles, roles],
  );

  const doFormSubmit: (values: RoleNameFormEntries) => void = (values) => {
    void setDisplayNames({
      customDisplayNames: strongKeys(values).flatMap((set) => {
        const eEntry = isNotNil(values[set].eMRole)
          ? [{ roleId: values[set].eMRole.roleId, displayName: values[set].eMRole.displayName }]
          : [];
        const mEntry = isNotNil(values[set].eERole)
          ? [{ roleId: values[set].eERole.roleId, displayName: values[set].eERole.displayName }]
          : [];
        return [...eEntry, ...mEntry];
      }),
    }).then(() => {
      refetch();
      onClose();
      notifications.show({
        title: 'Successfully updated',
        message: 'Role names were updated successfully',
      });
    });
  };
  return (
    <form onSubmit={form.onSubmit(doFormSubmit)}>
      <Text c="dimmed" size="xs" mb="lg">
        User roles are used to control user interfaces and other user configurations.
      </Text>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>User Roles</Table.Th>
            {hasElectrical && <Table.Th>Display Name (Electrical)</Table.Th>}
            {hasMechanical && <Table.Th>Display Name (Mechanical)</Table.Th>}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {rowData.map((r) => (
            <Table.Tr key={r.defaultDisplayName} flex={1}>
              <Table.Td>
                <Text m={5}>{r.defaultDisplayName}</Text>
              </Table.Td>
              {hasElectrical && (
                <Table.Td>
                  <TextInput
                    m={5}
                    w="100%"
                    maxLength={64}
                    {...form.getInputProps(`${r.defaultDisplayName}.eERole.displayName`)}
                  />
                </Table.Td>
              )}
              {hasMechanical && (
                <Table.Td>
                  <TextInput
                    m={5}
                    w="100%"
                    maxLength={64}
                    {...form.getInputProps(`${r.defaultDisplayName}.eMRole.displayName`)}
                  />
                </Table.Td>
              )}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      <Flex w="100%" justify="flex-end" gap="sm" mt="sm">
        <ModalButtons
          onClose={onClose}
          cancellationText="Cancel"
          confirmationText="Save"
          loading={loading}
          disabled={!form.isDirty()}
          type="submit"
        />
      </Flex>
    </form>
  );
};

export const RoleNamesModal = ({ opened, ...rest }: Props) => (
  <Modal opened={opened} onClose={rest.onClose} centered title="Rename User Roles" size="xl">
    <ModalContents {...rest} />
  </Modal>
);
