// Copyright ©️ 2025 eVolve MEP, LLC
import { differenceInDays, isValid, parseISO } from 'date-fns';

import type { StatusKey } from 'constants/badgeMappingStatus';
import { type DateOnly, type ISOTimestamp, today } from 'helpers/dateOnly';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import type { WorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import type { WorkRequest } from './types';
import type { Task } from '../../WorkRequest/WorkRequestPage/types';

// TODO: Move this somewhere common
export const facilityProjectTypeToQueryParam: Record<SelectedItem['type'], string> = {
  FACILITY: 'facilityId',
  PROJECT: 'projectId',
};

export const getDaysRemaining = (status: StatusKey, needBy?: DateOnly, statusDateTimestamp?: ISOTimestamp) => {
  const firstDate = isNotNil(needBy) ? parseISO(needBy) : null;
  if (isNil(firstDate) || !isValid(firstDate)) return 0;
  if (status === 'Completed') {
    const statusDate = isNotNil(statusDateTimestamp) ? parseISO(statusDateTimestamp) : null;
    if (isNil(statusDate) || !isValid(statusDate)) return 0;
    return differenceInDays(firstDate, statusDate);
  }
  return differenceInDays(firstDate, parseISO(today()));
};

export const getDaysRemainingWorkRequest = (
  workRequest: Pick<WorkRequest, 'workRequestStatusName' | 'needBy' | 'lastStatusUpdate'>,
) => getDaysRemaining(workRequest.workRequestStatusName, workRequest.needBy, workRequest.lastStatusUpdate);

export const getDaysRemainingWorkCellTask = (task: Task, workCellTask: WorkCellTask) =>
  getDaysRemaining(task.taskStatusTypeName, workCellTask.needBy, workCellTask.taskStatusTypeUpdatedOn);
