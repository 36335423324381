// Copyright ©️ 2025 eVolve MEP, LLC

import { useEffect, useMemo, useState } from 'react';

import type { MultiSelectProps } from '@mantine/core';

import type { StrongOmit } from 'helpers/helper-types';
import { isNil, isNotNil } from 'helpers/isNotNil';

import type { SearchableSelectorProps } from './searchableTypes';
import { type TypeSafeGroupComboboxData, type TypeSafeMultiSelectProps, WrappedMultiSelect } from './TypeSafeSelect';
import { useSearchableData } from './useSearchableData';

type Props<T, TKey extends keyof T> = SearchableSelectorProps<T, TKey> &
  StrongOmit<MultiSelectProps & TypeSafeMultiSelectProps, 'value' | 'data' | 'onChange' | 'onOptionSubmit'> & {
    disableSearch?: boolean;
    getGroup?: (data: T) => string | undefined;
    nowrap?: boolean;
  };

/**
 * Used for MutliSelects whose "search" functinality
 * involves fetching data from the backend.
 */
export const SearchableMultiSelect = <T, TKey extends keyof T>({
  paginatedGet,
  getItemLabel,
  idKey,
  value,
  onChange,
  excludeIds,
  setIsDirty,
  fetchPageOpts,
  searchKey,
  searchValue,
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  useLike,
  getGroup,
  preselectedOptions = [],
  disableOption,
  onOptionSubmit,
  disableSearch = false,
  placeholder = 'Type to search...',
  nothingFoundMessage = 'No results found',
  ...multiSelectProps
}: Props<T, TKey>) => {
  const [opened, setOpened] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const { options, setIsLazy, loading, setSearchPhrase } = useSearchableData({
    paginatedGet,
    fetchPageOpts,
    searchKey,
    useLike,
    lazy: true,
  });
  useEffect(() => {
    if (opened && (loading || disableSearch)) {
      setDropdownOpened(true);
    }
  }, [disableSearch, loading, opened]);
  useEffect(() => {
    if (opened) {
      setIsLazy(disableSearch);
    } else {
      setIsLazy(true);
    }
  }, [disableSearch, opened, setIsLazy]);
  const [selectedOptions, setSelectedOptions] = useState<T[]>(preselectedOptions);

  useEffect(() => {
    if (isNotNil(searchValue)) {
      setSearchPhrase(searchValue);
    }
  }, [searchValue, setSearchPhrase]);

  useEffect(() => setSelectedOptions((o) => value ?? o), [value]);

  const data = useMemo(() => {
    if (isNil(getGroup)) {
      return [
        ...selectedOptions.map((t) => ({
          label: getItemLabel(t),
          value: t[idKey] as string,
        })),
        ...options
          .filter((t) => !excludeIds?.includes(t[idKey]) && !selectedOptions.some((st) => st[idKey] === t[idKey]))
          .map((t) => ({
            label: getItemLabel(t),
            value: t[idKey] as string,
            disabled: disableOption?.(t),
          })),
      ];
    }
    const groupedData: TypeSafeGroupComboboxData<string, object>[] = [];
    const opts = [
      ...selectedOptions.map((t) => ({
        original: t,
        label: getItemLabel(t),
        value: t[idKey] as string,
      })),
      ...options
        .filter((t) => !excludeIds?.includes(t[idKey]) && !selectedOptions.some((st) => st[idKey] === t[idKey]))
        .map((t) => ({
          original: t,
          label: getItemLabel(t),
          value: t[idKey] as string,
          disabled: disableOption?.(t),
        })),
    ];
    opts.forEach(({ original, ...opt }) => {
      const group = getGroup(original) ?? 'Other';
      const existing = groupedData.find((d) => d.group === group);
      if (!existing) {
        groupedData.push({
          group,
          items: [opt],
        });
      } else {
        existing.items.push(opt);
      }
    });
    return groupedData;
  }, [disableOption, excludeIds, getGroup, getItemLabel, idKey, options, selectedOptions]);

  return (
    <WrappedMultiSelect
      placeholder={placeholder}
      nothingFoundMessage={disableSearch ? undefined : loading ? 'Loading...' : nothingFoundMessage}
      searchable
      searchValue={searchValue}
      onSearchChange={setSearchPhrase}
      filter={(d) => d.options}
      hidePickedOptions
      dropdownOpened={dropdownOpened}
      onDropdownClose={() => setDropdownOpened(false)}
      {...multiSelectProps}
      creatable={multiSelectProps.creatable && !loading}
      onFocus={(...e) => {
        setOpened(true);
        multiSelectProps.onFocus?.(...e);
      }}
      onBlur={(...e) => {
        setOpened(false);
        setDropdownOpened(false);
        multiSelectProps.onBlur?.(...e);
      }}
      onChange={(v) => {
        const ids = v as T[TKey][];
        const stillSelected = selectedOptions.filter((t) => ids.includes(t[idKey]));
        const additionalSelected = options.filter(
          (o) => ids.includes(o[idKey]) && !stillSelected.some((t) => t[idKey] === o[idKey]),
        );
        const newSelectedOptions = [...stillSelected, ...additionalSelected];
        setIsDirty?.(true);
        setSelectedOptions(newSelectedOptions);
        onChange(newSelectedOptions);
      }}
      onOptionSubmit={(id) => {
        if (isNotNil(onOptionSubmit)) {
          const opt = selectedOptions.find((o) => o[idKey] === id) ?? options.find((o) => o[idKey] === id);
          if (opt) {
            onOptionSubmit(opt);
          }
        }
      }}
      value={selectedOptions.map((t) => t[idKey] as string)}
      data={
        loading
          ? selectedOptions.map((t) => ({
              label: getItemLabel(t),
              value: t[idKey] as string,
            }))
          : data
      }
    />
  );
};
