// Copyright ©️ 2025 eVolve MEP, LLC

import { useLocation, type Location } from 'react-router-dom';

import type { StrongOmit } from 'helpers/helper-types';

import type { LocationState } from './types';

/** Use in place of useLocation */
export const useEvolveLocation = (): StrongOmit<Location, 'state'> & {
  state: LocationState;
} => useLocation();
