// Copyright ©️ 2025 eVolve MEP, LLC

import { DateInput, type DateInputProps } from '@mantine/dates';
import { parseISO } from 'date-fns';
import omit from 'lodash.omit';

import { type DateOnly, dateOnly as toDateOnly } from 'helpers/dateOnly';
import type { StrongOmit } from 'helpers/helper-types';
import { isNotNil } from 'helpers/isNotNil';

type DateOnlyProps = {
  value?: DateOnly | null;
  onChange: (dateOnly: DateOnly | null) => void;
};

export const DateInputWrapped = (
  props: StrongOmit<DateInputProps, keyof DateOnlyProps> &
    (
      | ({
          dateOnly: true;
        } & DateOnlyProps)
      | ({
          dateOnly?: false;
        } & Pick<DateInputProps, keyof DateOnlyProps>)
    ),
) => (
  <DateInput
    {...omit(props, 'dateOnly')}
    value={props.dateOnly ? (isNotNil(props.value) ? parseISO(props.value) : props.value) : props.value}
    onChange={(v) => {
      if (!props.dateOnly) props.onChange?.(v);
      else if (!v) props.onChange(v);
      else props.onChange(toDateOnly(v));
    }}
  />
);
