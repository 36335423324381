// Copyright ©️ 2025 eVolve MEP, LLC
import type { StatusKey } from 'constants/badgeMappingStatus';
import type { ISOTimestamp } from 'helpers/dateOnly';
import type {
  TaskTypeId,
  WorkRequestItemId,
  WorkRequestItemStatusTypeId,
  WorkRequestItemTypeId,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type { UnitOfMeasureId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';
import type { WorkRequestId } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import type {
  ASSEMBLY_EDGE_TYPE_ID,
  ASSEMBLY_NODE_ASSEMBLY_TYPE_ID,
  ASSEMBLY_NODE_PART_TYPE_ID,
  ASSEMBLY_NODE_SHOP_TASK_TYPE_ID,
  HANDLE_POSITION_ID,
} from 'modules/Materials/AssemblyEditor/Utils/constants';
import type { AssemblyEdgeId, AssemblyNodeId } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/types';
import type { UserId } from 'types/types-api';

import type { WorkOrderId } from '../../WorkOrdersPage/types';
import type { PartId } from './SecondaryPane/AddItems/types';

declare const workOrderItemIdSymbol: unique symbol;
export type WorkOrderItemId = string & { [workOrderItemIdSymbol]: never };

declare const billOfProcessIdSymbol: unique symbol;
export type BillOfProcessId = string & { [billOfProcessIdSymbol]: never };

export type WorkOrderItem = {
  // WorkOrderItemIdentification: null;
  assignedQuantity: number;
  billOfProcessId: BillOfProcessId;
  createdBy: UserId;
  createdOn: ISOTimestamp;
  hasShopTask: boolean;
  quantity: number;
  remainingQuantity: number;
  unitOfMeasureId: UnitOfMeasureId;
  unityQuantity: number;
  workOrderId: WorkOrderId;
  workOrderItemId: WorkOrderItemId;
  workOrderItemName: string;
  workOrderItemStatusTypeId: WorkOrderItemStatusTypeId;
  workOrderItemTypeId: WorkOrderItemTypeId;
  workRequestItemId: WorkRequestItemId;
  hasDecisions?: boolean;
  hasDecisionsToMake?: boolean;
  partId?: PartId;
  partVersionNumber?: number;
};

declare const workOrderItemTypeIdSymbol: unique symbol;
export type WorkOrderItemTypeId = string & { [workOrderItemTypeIdSymbol]: never };

/**
 * "Magic" UUIDs. These are hardcoded on the backend and are the same in each env.
 *
 * Even though they have a different name,
 * the WorkOrderItemStatusTypeIds are the same as the WorkRequestItemStatusTypeIds.
 */
export type WorkOrderItemStatusTypeId = WorkRequestItemStatusTypeId;

export const workOrderStatusMap: Record<WorkRequestItemStatusTypeId | WorkOrderItemStatusTypeId, StatusKey> = {
  'c06f1253-fd88-48ac-b2ea-bb2fdb743741': 'Not Started',
  '237130d0-3dea-4353-89c4-e30d8b405107': 'Started',
  '274c5ba4-c137-434e-8249-84c5d5f72faf': 'Completed',
} as const;

export type CreateOrUpdateWorkOrderItemBody = {
  quantity?: number;
  workOrderItemName?: string;
  workOrderId?: WorkOrderId;
  workOrderItemIdentification?: '';
  workOrderItemTypeId?: WorkOrderItemTypeId;
  partId?: PartId;
  unitOfMeasureId?: UnitOfMeasureId;
};

export type CreateOrUpdateWorkRequestItemBody = {
  quantity?: number;
  workRequestItemName?: string;
  workRequestId?: WorkRequestId;
  workRequestItemIdentification?: '';
  workRequestItemTypeId?: WorkRequestItemTypeId;
  partId?: PartId;
  unitOfMeasureId?: UnitOfMeasureId;
};

export type NodeReferenceId = AssemblyNodeId;

export type AssemblyEdge = {
  assemblyEdgeTypeId: typeof ASSEMBLY_EDGE_TYPE_ID;
  beginHandlePositionId: HANDLE_POSITION_ID;
  beginNodeReferenceId: NodeReferenceId;
  endHandlePositionId: HANDLE_POSITION_ID;
  endNodeReferenceId: NodeReferenceId;
};

export type BaseAssemblyNode = {
  assemblyNodeName: string;
  assemblyNodeDescription: string | null;
  assemblyNodeTypeId:
    | typeof ASSEMBLY_NODE_SHOP_TASK_TYPE_ID
    | typeof ASSEMBLY_NODE_PART_TYPE_ID
    | typeof ASSEMBLY_NODE_ASSEMBLY_TYPE_ID;
  positionX?: number;
  positionY?: number;
} & ({ partId: PartId; quantity: number } | { shopTaskId: TaskTypeId });

export type AssemblyNode = BaseAssemblyNode & {
  assemblyNodeId: AssemblyNodeId;
};

export type NewAssemblyNode = BaseAssemblyNode & {
  referenceId: NodeReferenceId;
};

export type NewAssemblyBody = {
  partId: PartId;
  edges: AssemblyEdge[];
  nodes: NewAssemblyNode[];
};

export type UpdateAssemblyBody = {
  addNodes?: NewAssemblyNode[];
  addEdges?: AssemblyEdge[];
  updateNodes?: AssemblyNode[];
  updateEdges?: Partial<AssemblyEdge> & {
    assemblyEdgeId: AssemblyEdgeId;
  };
  deleteNodes?: AssemblyNodeId[];
  deleteEdges?: AssemblyEdgeId[];
};
