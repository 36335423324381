// Copyright ©️ 2025 eVolve MEP, LLC

import { useState } from 'react';

import { ActionIcon, Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';

import type { WorkOrder } from './types';
import { useWorkOrders } from './useWorkOrders';

type Props = {
  startEditingWorkOrder: () => void;
  workOrder: WorkOrder;
  refresh: () => void;
};

export const EditWorkOrderMenu = ({ startEditingWorkOrder, workOrder, refresh }: Props) => {
  const { deleteWorkOrder, mutationRunning } = useWorkOrders();
  const [deleting, setDeleting] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onConfirm = () => {
    setDeleting(true);
    void deleteWorkOrder(workOrder.workOrderId)
      .then(() => {
        notifications.show({
          title: 'Successfully deleted',
          message: `Deleted work order ${workOrder.workOrderName}`,
          color: 'green',
        });
        setModalOpen(false);
        refresh();
      })
      .finally(() => setDeleting(false));
  };

  return (
    <>
      <Menu position="bottom-start" disabled={mutationRunning} withinPortal opened={menuOpen} onChange={setMenuOpen}>
        <Menu.Target>
          <ActionIcon>
            <EvolveIcon className={menuOpen ? '' : 'show-on-row-hover'} icon="MoreOptsVert" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={startEditingWorkOrder}>View properties</Menu.Item>
          {workOrder.workOrderStatusTypeName !== 'Completed' && (
            <Menu.Item color="red" onClick={() => setModalOpen(true)}>
              Delete
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <ConfirmationModal
        description={`Are you sure you want to delete ${workOrder.workOrderName}?`}
        opened={modalOpen}
        onConfirm={onConfirm}
        onClose={() => setModalOpen(false)}
        loading={deleting}
        buttonColor="red"
      />
    </>
  );
};
