// Copyright ©️ 2025 eVolve MEP, LLC

import { strongEntries, strongFromEntries } from 'helpers/strongEntries';

// Memoize the env variables so that we can ensure the file only loads once.
let envVariablesPromise: Promise<Record<string, string>>;
export const getEnvVars = async (): typeof envVariablesPromise => {
  // If this promise exists already, return it
  // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/no-unnecessary-condition
  if (envVariablesPromise) return await envVariablesPromise;

  envVariablesPromise = new Promise((resolve) => {
    // set defaults from the compiled configuration files
    const envVariables = {
      ...strongFromEntries(strongEntries(import.meta.env).filter(([name]) => String(name).startsWith('VITE_APP'))),
      REACT_APP_API_BASE_URL: import.meta.env.VITE_APP_API_BASE_URL,
      REACT_APP_FORESITE_SYNC_UI_URL: import.meta.env.VITE_APP_FORESITE_SYNC_UI_URL,
      REACT_APP_RUM_ACCOUNT_ID: import.meta.env.VITE_APP_RUM_ACCOUNT_ID,
      REACT_APP_RUM_APP_ID: import.meta.env.VITE_APP_RUM_APP_ID,
      REACT_APP_RUM_IDENTITY_POOL_ID: import.meta.env.VITE_APP_RUM_IDENTITY_POOL_ID,
      REACT_APP_RUM_UNIQUE_ID: import.meta.env.VITE_APP_RUM_UNIQUE_ID,
      NULLSTONE_ENV: import.meta.env.VITE_NULLSTONE_ENV,
    };

    // if an env.json file is present, overwrite the environment variables with these values
    void fetch('/env.json')
      .then((response) => response.json())
      .then((ev) => ({ ...envVariables, ...ev }))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        return envVariables;
      })
      .then(resolve);
  });
  return await envVariablesPromise;
};
