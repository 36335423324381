// Copyright ©️ 2025 eVolve MEP, LLC
import { useState } from 'react';

import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AlertingPopover } from 'components/Mantine/AlertingPopover';
import { BoxWithRef } from 'components/Mantine/BoxWithRef';
import { SearchableMultiSelect } from 'components/Mantine/SearchableMultiSelect';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { TaskType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type { WorkCell } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import { useWorkCellSetup } from '../WorkCellSetupContext';

type Props = {
  taskTypes: TaskType[];
  onComplete: () => void;
};

export const MapTaskToWorkCellPopover = ({ taskTypes, onComplete }: Props) => {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState<WorkCell | null>(null);
  const [saving, setSaving] = useState(false);
  const {
    selectedFacility,
    draftShopConfiguration,
    mapWorkCellTaskType,
    serverSideGridProps: { refreshDetailGrid, expandDetailGrid },
  } = useWorkCellSetup();
  const { data, ...paginatedGet } = useWrappedPaginatedGet<WorkCell>('shop/workCell', {
    lazy: true,
    defaultConfig: {
      params: {
        facilityId: selectedFacility.id,
        shopConfigurationId: draftShopConfiguration?.shopConfigurationId,
      },
    },
  });

  const onSubmit = () => {
    if (isNil(selected)) return;
    setSaving(true);
    void Promise.allSettled(taskTypes.map((taskType) => mapWorkCellTaskType(selected.workCellId, taskType.taskTypeId)))
      .then((x) => {
        const successful = x.filter((p) => p.status === 'fulfilled').length;
        if (successful > 0) {
          notifications.show({
            title: 'Successfully mapped',
            message: `Mapped ${successful} task${successful === 1 ? '' : 's'} to ${selected.workCellName}`,
            color: 'green',
          });
          expandDetailGrid(selected.workCellId, 'Tasks');
          refreshDetailGrid(selected.workCellId, 'Tasks');
          setSelected(null);
          onComplete();
          setOpened(false);
        }
      })
      .finally(() => setSaving(false));
  };

  return (
    <AlertingPopover
      opened={opened}
      onClose={() => {
        setOpened(false);
        setSelected(null);
      }}
      isDirty={!!selected}
      onSubmit={onSubmit}
      disabled={isNil(selected)}
      loading={saving}
      target={
        <BoxWithRef>
          <Button
            onClick={() => setOpened(true)}
            variant="outline"
            leftSection={<EvolveIcon icon="Pin" size="sm" />}
            disabled={taskTypes.length === 0}
          >
            Map to work cell
          </Button>
        </BoxWithRef>
      }
    >
      <SearchableMultiSelect
        label="Work Cell"
        paginatedGet={{ data, ...paginatedGet }}
        searchKey="workCellName"
        idKey="workCellId"
        getItemLabel={(d) => d.workCellName}
        disabled={saving}
        clearable
        value={isNotNil(selected) ? [selected] : []}
        // Instead of onChange, use onOptionSubmit to handle value setting
        // eslint-disable-next-line no-empty-function
        onChange={() => {}}
        blurOnChange
        onOptionSubmit={(s) => setSelected(s)}
        excludeIds={[data.find((wc) => wc.workCellName === 'Unassigned')?.workCellId].filter(isNotNil)}
      />
    </AlertingPopover>
  );
};
