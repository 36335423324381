// Copyright ©️ 2025 eVolve MEP, LLC
import { Alert } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isKeyOf } from 'helpers/isKeyOf';
import { isNotNil } from 'helpers/isNotNil';

export type LoginErrorCode =
  | 'UserNotVerifiedFoundException'
  | 'LimitExceededException'
  | 'NotAuthorizedException'
  | 'UserNotFoundException'
  | 'EmailNotVerified'
  | 'InvitationExpired'
  | 'EmailAlreadyExists'
  // Catch-all, frontend only
  | 'UnknownException';

const loginErrorCopy: Record<LoginErrorCode, string> = {
  EmailNotVerified: 'Your email address has not been verified!',
  InvitationExpired: 'Sorry, this invitation has expired.',
  LimitExceededException: 'Too many attemps, try again later.',
  NotAuthorizedException: 'Invalid email or password.',
  UserNotFoundException: 'Invalid email or password.',
  UnknownException: 'Something went wrong. Please try again later.',
  EmailAlreadyExists: 'TODO',
  UserNotVerifiedFoundException: '',
};

type Props = {
  errorCode: LoginErrorCode | string | undefined | null;
  onClose?: () => void;
};

export const LoginErrorAlert = ({ errorCode, onClose }: Props) =>
  errorCode ? (
    <Alert
      variant="light"
      color="red"
      withCloseButton={isNotNil(onClose)}
      icon={<EvolveIcon icon="Info" color="red" />}
      onClose={onClose}
    >
      {isKeyOf(errorCode, loginErrorCopy) ? loginErrorCopy[errorCode] : errorCode}
    </Alert>
  ) : null;
