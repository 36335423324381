// Copyright ©️ 2025 eVolve MEP, LLC

import { Divider, Flex } from '@mantine/core';

import { isKeyOf } from 'helpers/isKeyOf';
import { isNil } from 'helpers/isNotNil';
import { useSetupModule } from 'hooks/useSetupModule';
import { WithColorScheme } from 'styles/theme/ColorScheme';

import { NavItem } from './NavItem';
import { moduleMapping } from '../navigation';

export const NavBarContent = () => {
  const { currentModule } = useSetupModule();
  const selectedModule = currentModule();

  if (isNil(selectedModule) || !isKeyOf(selectedModule, moduleMapping)) return null;

  const navigationModuleSets = moduleMapping[selectedModule];
  return (
    <WithColorScheme colorScheme="dark">
      <Flex direction="column" gap="xs" mt="xs">
        {navigationModuleSets.map((item, i) =>
          item === 'divider' ? (
            // eslint-disable-next-line react/no-array-index-key
            <Divider key={`divider-${i}`} color="gray.7" />
          ) : (
            <NavItem key={`${currentModule()}-${item.displayText}`} item={item} />
          ),
        )}
      </Flex>
    </WithColorScheme>
  );
};
