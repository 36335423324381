// Copyright ©️ 2024 eVolve MEP, LLC
import React, { useEffect } from 'react';

import { Flex, MantineProvider } from '@mantine/core';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import { useDeviceSize } from 'hooks/useDeviceSize';
import { getLocalStorage } from 'hooks/useLocalStorage';

import { AcceptInvitation } from './AcceptInvitation';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { Login } from './Login/Login';
import { ResetPassword } from './ResetPassword';
import { VerifyEmailPrompt } from './VerifyEmailPrompt';

const AuthBackground = React.lazy(() => import('./AuthBackground'));

export const AuthenticationRoutes = () => {
  const navigate = useNavigate();
  const { deviceSize } = useDeviceSize();

  useEffect(() => {
    if (getLocalStorage('EVOLVE_ACCESS_TOKEN')) {
      void navigate('/');
    }
  }, [navigate]);

  return (
    <MantineProvider theme={{ primaryColor: 'indigo' }}>
      <Flex
        direction={deviceSize === 'DESKTOP' ? 'row-reverse' : 'column'}
        style={{
          backgroundColor: 'white',
        }}
      >
        <AuthBackground />
        <Flex
          direction="row"
          align="center"
          justify="center"
          style={{
            width: '100%',
            minHeight: 'calc(100vh - 120px)',
            ...(deviceSize === 'DESKTOP'
              ? {
                  width: '40%',
                  minHeight: 'calc(100vh - 425px)',
                  maxWidth: '700px',
                }
              : {}),
          }}
        >
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="password-reset" element={<ResetPassword />} />
            <Route path="email-confirmation" element={<VerifyEmailPrompt />} />
            <Route path="accept" element={<AcceptInvitation />} />
            <Route path="*" element={<Navigate to="login" />} />
          </Routes>
        </Flex>
      </Flex>
    </MantineProvider>
  );
};
