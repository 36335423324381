// Copyright ©️ 2025 eVolve MEP, LLC
export const EDGE_TYPE_ID_DICT = {
  a: 'd60d0d15-63ae-4c11-b5f4-fe561bbfd9e9', // left
  b: '8860a0ad-e32a-4fcb-8208-f2334da65f3b', // right
  c: '925d7c6a-79ea-471d-94cc-dad15f1f9265', // top
  d: 'cb969427-0412-469b-b333-f3f460a51fd6', // bottom
} as const;
export type HANDLE_POSITION_ID = (typeof EDGE_TYPE_ID_DICT)[keyof typeof EDGE_TYPE_ID_DICT];
export const EDGE_TYPE_POSITION_DICT = {
  [EDGE_TYPE_ID_DICT.a]: 'left',
  [EDGE_TYPE_ID_DICT.b]: 'right',
  [EDGE_TYPE_ID_DICT.c]: 'top',
  [EDGE_TYPE_ID_DICT.d]: 'bottom',
};

export const ASSEMBLY_EDGE_TYPE_ID = 'b1009c0d-66ad-4530-8c04-3e85899a9e80';
export const ASSEMBLY_NODE_SHOP_TASK_TYPE_ID = 'f6d49465-83e5-4b77-a315-38247a8984b6';
export const ASSEMBLY_NODE_PART_TYPE_ID = '824a796f-7de2-4a7e-8ec0-7d65a4a0562e';
export const ASSEMBLY_NODE_ASSEMBLY_TYPE_ID = 'assemblyType';

export const DEFAULT_ASSEMBLY_NODE_POSITION = {
  positionX: 550,
  positionY: 350,
};
