// Copyright ©️ 2025 eVolve MEP, LLC
import { Flex } from '@mantine/core';
import type { ColDef } from 'ag-grid-enterprise';

import { EvolveIcon, type EvolveIconName } from 'assets/icons/EvolveIcon';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import { sortByStatusKey, workRequestStatusNames } from 'constants/badgeMappingStatus';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { filterStatusConverter } from 'helpers/ag-grid/convertAgGridRequest';
import { formatDate } from 'helpers/dateOnly';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { AttachmentsPopover } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/AttachmentsPopover';
import type { Spool } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import { getDaysRemainingWorkRequest } from './common';
import { EditWorkRequestMenu } from './EditWorkRequestMenu';
import type { BOMItem, WorkRequest, WorkRequestStatusType } from './types';
import { WorkRequestActionButtons } from './WorkRequestActionButtons';
import { CellStatusBadge } from '../WorkRequestListElements/CellComponentStatus/CellStatusBadge';

export const getWorkRequestColDef = (
  workRequestStatuses: WorkRequestStatusType[],
  facilityOrProject: NonNullable<SelectedItem['type']>,
  setSelectedWorkRequest: (workRequest: WorkRequest) => void,
  showOpen: boolean,
): ColDef<WorkRequest>[] => [
  {
    ...lockedColDef(),
    cellRenderer: 'agGroupCellRenderer',
    width: 48,
  },
  {
    field: 'workRequestIdentifier',
    headerName: 'Work Request Id',
    cellRenderer: CellRenderer<WorkRequest>(({ value, data, node }) => (
      <EvolveLink
        to={`${data.workRequestId}`}
        from="Work Requests"
        onClick={(event) => {
          const isShortcutKeyPressed = event.ctrlKey || event.shiftKey || event.metaKey;
          // If a shortcut key is pressed, we can assume they're opening in a new tab,
          // so we shouldn't update the current tab by calling setSelectedWorkRequest
          if (!isShortcutKeyPressed) {
            setSelectedWorkRequest(node.data);
          }
        }}
      >
        <Flex align="center" gap="xs">
          <EvolveIcon icon="WorkRequest" light />
          {value}
        </Flex>
      </EvolveLink>
    )),
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    ...(facilityOrProject === 'FACILITY'
      ? {
          field: 'projectName',
          headerName: 'Project Name',
        }
      : {
          field: 'facilityName',
          headerName: 'Facility',
        }),
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'workRequestName',
    flex: 1,
    minWidth: 220,
    headerName: 'Work Request Name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'workRequestStatusId',
    // Set so that the field rerenders if either of these fields changes,
    // the cell will rerender
    valueGetter: ({ data }) => `${data?.workRequestStatusName}_${data?.needBy}`,
    headerName: 'Status',
    minWidth: 120,
    width: 120,
    cellRenderer: CellRenderer<WorkRequest>(({ data }) => (
      <CellStatusBadge status={data.workRequestStatusName} daysRemaining={getDaysRemainingWorkRequest(data)} />
    )),
    sortable: false,
    filter: showOpen ? 'customValueSetFilter' : false,
    filterParams: filterStatusConverter(
      workRequestStatuses.sort(sortByStatusKey(workRequestStatusNames, 'workRequestStatusTypeName')),
      'workRequestStatusTypeId',
      'workRequestStatusTypeName',
    ),
  },
  {
    field: 'needBy',
    headerName: 'Need By',
    width: 150,
    valueGetter: ({ data }) => formatDate(data?.needBy, 'MM/dd/yyyy'),
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: ['inRange'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    headerName: showOpen ? 'Days Remaining' : 'Days Early/Late',
    headerComponent: () =>
      showOpen ? (
        'Days Remaining'
      ) : (
        <>
          <span>Days Early/</span>
          <span style={{ color: '#E03131' }}>Late</span>
        </>
      ),
    width: 150,
    valueGetter: ({ data }) => (isNotNil(data) && isNotNil(data.needBy) ? getDaysRemainingWorkRequest(data) : ''),
    cellStyle: ({ value }) => ({
      ...(value < 0 ? { color: '#E03131' } : {}),
    }),
    valueFormatter: ({ value }) => (showOpen ? value : Math.abs(value)),
    sortable: false,
  },
  {
    field: 'workRequestDescription',
    tooltipField: 'workRequestDescription',
    headerName: 'Comments',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
    sortable: false,
  },
  {
    ...lockedColDef('right'),
    width: 80,
    cellRenderer: CellRenderer<WorkRequest>(({ data }) => <AttachmentsPopover workRequest={data} />, {
      flexProps: { justify: 'flex-end' },
    }),
  },
  {
    ...lockedColDef('right'),
    width: 150,
    cellRenderer: CellRenderer<WorkRequest>(
      ({ data, node }) => <WorkRequestActionButtons workRequest={data} onUpdate={(w) => node.updateData(w)} />,
      { flexProps: { justify: 'flex-end' } },
    ),
  },
  {
    ...lockedColDef('right'),
    width: 50,
    cellRenderer: CellRenderer<WorkRequest>(({ data, node, api }) => (
      <EditWorkRequestMenu
        startEditingWorkRequest={() => node.setSelected(true, true)}
        workRequest={data}
        refresh={() => api.refreshServerSide({ purge: true })}
      />
    )),
  },
];

const getBomIcon = (bomItem: BOMItem | undefined): EvolveIconName | null => {
  if (isNil(bomItem)) return null;
  if (bomItem.itemType === 'Part') return 'Part';
  if (bomItem.itemType === 'PC Assembly') return 'Assembly';
  if (bomItem.itemType === 'Write-In') return 'WriteInItem';
  return null;
};

export const bomItemColDef: ColDef<BOMItem>[] = [
  {
    field: 'item',
    tooltipField: 'item',
    minWidth: 200,
    flex: 3,
    cellRenderer: CellRenderer<BOMItem>(({ value, data }) => (
      <>
        <EvolveIcon icon={getBomIcon(data)} light />
        {value}
      </>
    )),
  },
  {
    field: 'spoolQuantity',
    headerName: 'Spool Qty',
    minWidth: 100,
    flex: 1,
    // Using || instead of ?? as we want to render 0 as empty string
    valueFormatter: ({ value }) => value || '',
  },
  { field: 'quantityRequired', headerName: 'Qty', minWidth: 80, flex: 1 },
  { field: 'unitOfMeasure', headerName: 'UOM', minWidth: 100, flex: 1 },
  { field: 'manufacturer', tooltipField: 'manufacturer', minWidth: 120, flex: 1 },
  { field: 'catalogNumber', tooltipField: 'catalogNumber', headerName: 'Catalog #', minWidth: 120, flex: 1 },
];

export const spoolsItemColDef: ColDef<Spool>[] = [
  {
    field: 'spool',
    minWidth: 300,
    flex: 1,
    cellRenderer: CellRenderer<Spool, Spool['spool']>(({ value }) => (
      <>
        <EvolveIcon icon="Spools" light />
        {value}
      </>
    )),
  },
];
